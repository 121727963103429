/// <reference types="googlemaps" />

import telemetry from '../../../telemetry';

export function initGoogleAutoSuggest(options: InitGoogleAutoSuggestOptions): EventListener {
  const {
    inputId, types, onPlaceChanged, searchAction, onFormChange
  } = options;
  const input = document.getElementById(inputId);
  const autocomplete = new (window as any).google.maps.places.Autocomplete(
    (input), {
      types: [types],
      componentRestrictions: { country: ['us', 'gu', 'mp'] }
    }
  );
  autocomplete.setFields(['address_components', 'place_id']);

  const autocompleteListener = autocomplete.addListener('place_changed', () => {
    onPlaceChanged(autocomplete, onFormChange, searchAction);
  });

  (window as any).google.maps.event.addDomListener(input, 'keydown', (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  });
  return autocompleteListener;
}

export function geocodeGooglePlace(placeId: string):Promise<GeometryLocation> {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new (window as any).google.maps.Geocoder();
      geocoder.geocode({ placeId }, (results: google.maps.GeocoderResult[]) => {
        if (!results.length) return;
        const lat = results[0].geometry?.location.lat();
        const lng = results[0].geometry?.location.lng();
        resolve({ lat, lng });
      });
    } catch (error) {
      telemetry.addNoticeError(error as unknown as Error);
      reject(error);
    }
  });
}

export function removeGoogleAutocomplete(listener: EventListener) {
  (window as any).google.maps.event.removeListener(listener);

  // remove .pac-container(auto complete dropdown from Google)
  // after disabling autocomplete listener
  const removeElements = (elms: NodeListOf<Element>) => elms.forEach((el) => el.remove());
  removeElements(document.querySelectorAll('.pac-container'));
}
