import { useSelector } from 'react-redux';
import { CartItem, Modifier, Product } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { useCCGetUpsellQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetUpsellQuery';
import { RootState } from '@/rootStateTypes';
import ProductId from '@/common/ProductId';
import { useCartRail } from '../../../hooks/useCartRail';
import {
  transformOneClickMods
} from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts/helpers/menuProductsData';

type UseOneClickUpsellCartResponse = {
  products: Product[];
  isUpsellQueryLoading: boolean | null | undefined;
};

export type UseOneClickUpsellDecisionVariables = {
  product_id_to_default_size_map: { [key: string]: string };
};

function getOneClickOptionPrice(product: Product, selectedOptions: (Modifier | undefined)[], isYumEcomm: boolean) {
  if (isYumEcomm) {
    return product.price || selectedOptions?.find((option) => option?.variantCode)?.price;
  }

  return selectedOptions[0]?.price;
}

function getSelectedOptions(product: Product, size: Modifier | undefined, isYumEcomm: boolean) {
  if (isYumEcomm) {
    return product.selectedOptions;
  }

  return size ? [size, ...(size?.modifiers ?? [])] : [];
}

const getDefaultSelections = (yumProduct: Product) => yumProduct.selectedOptions?.filter(
  (selectedOption) => (selectedOption.slotCode && selectedOption.weightCode) || selectedOption.variantCode
) || [];

function transformProducts(products: Product[], defaultSizeMap: Record<string, string>, isYumEcomm: boolean): Product[] {
  return products.map((product) => {
    // Handle Melts Products
    if (product.name?.toLowerCase().includes('melt')) {
      const oneClickSelections = getDefaultSelections(product);
      const transformedOneClickDefaults = transformOneClickMods(oneClickSelections);

      return {
        ...product,
        selectedOptions: transformedOneClickDefaults
      } as Product;
    }

    // Handle Non-Melts Products
    const sizeGroup = product.options?.find((option) => option.name?.toLowerCase().includes('size'));
    const productId = new ProductId(product.id).globalId;
    const defaultSizeId = defaultSizeMap[productId];
    let size = sizeGroup?.modifiers?.[0];
    if (defaultSizeId) {
      const matchedSize = sizeGroup?.modifiers?.find((m) => new ProductId(m.id).globalId === defaultSizeId);
      if (matchedSize) {
        size = matchedSize;
      }
    }

    const selectedOptions = getSelectedOptions(product, size, isYumEcomm);

    const oneClickOptionPrice = getOneClickOptionPrice(product, selectedOptions, isYumEcomm);

    return {
      ...product,
      price: oneClickOptionPrice,
      selectedOptions
    } as Product;
  });
}

function getProductsNotInCart(itemsInCart: CartItem[], products: Product[]): Product[] {
  const cartItemsIds = itemsInCart.map((cartItem) => cartItem.id);
  return products.filter(
    (product) => cartItemsIds?.includes(product.id) === false
  );
}

function getPaidProducts(products: Product[]): Product[] {
  return products?.filter((product) => (product.price ?? -1) > 0) ?? [];
}

export const useOneClickUpsellCart = (): UseOneClickUpsellCartResponse => {
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [{ cart }] = useCartRail();
  const cartItems = cart?.items ?? [];
  const [{ variables: decisionVariables }] = useDecision('fr-dtg-38-cart-one-click-upsell');
  const productIdToDefaultSizeMap = (decisionVariables as UseOneClickUpsellDecisionVariables)?.product_id_to_default_size_map ?? {};

  const {
    data: upsellProducts,
    isLoading: isUpsellQueryLoading
  } = useCCGetUpsellQuery({ skipTransform: true });

  if (!upsellProducts) {
    return {
      products: [],
      isUpsellQueryLoading
    };
  }

  const productsNotInCart = getProductsNotInCart(cartItems, upsellProducts as Product[]);
  const transformedProducts = transformProducts(productsNotInCart, productIdToDefaultSizeMap, isYumEcomm);
  const paidProducts = getPaidProducts(transformedProducts);

  const firstThreeProducts = paidProducts.slice(0, 3);

  return {
    products: firstThreeProducts,
    isUpsellQueryLoading
  };
};
