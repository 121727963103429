import { InstrumentationAdapter } from './types';

class NewRelicAPM implements InstrumentationAdapter {
  /* eslint-disable global-require */
  agent = require('newrelic');

  addCustomAttribute(name: string, value: string) {
    this.agent.addCustomAttribute(name, value);
  }

  addCustomEvent(name: any, attributes: any) {
    this.agent.recordCustomEvent(name, attributes);
  }

  addNoticeError(error: Error, customAttributes?: {}) {
    this.agent.noticeError(error, customAttributes);
  }
}

export default NewRelicAPM;
