import cookie from 'next-cookies';
import { LocalizeCustomerCartInput } from '@pizza-hut-us-development/client-core';

import { CC_LOCALIZATION } from '@/configuration/constants';
import { getCookie } from '@/cookies/handlers';
import { COOKIES } from '@/cookies/constants';

export type LocalizationData = {
  cartId?: string | null;
  localizationDetails?: LocalizeCustomerCartInput | null;
};

export function getLocalizationData(ctx: any): LocalizationData {
  const { value: cartId } = getCookie(COOKIES.CART_CC_ID, ctx, false);

  // JSON is parsed automatically but cookie(ctx) return type is always string
  // https://github.com/matthewmueller/next-cookies#readme
  const localizationDetails = cookie(ctx)[CC_LOCALIZATION] as unknown as LocalizeCustomerCartInput | null ?? null;

  return { cartId: cartId || null, localizationDetails };
}
