import deepmerge from 'deepmerge';

const merge = (existing: any, incoming: any) => {
  // @ts-expect-error - existing code
  const overwriteMerge = (source, target) => target;

  return deepmerge(
    existing,
    incoming || {},
    { arrayMerge: overwriteMerge }
  );
};

export default merge;
