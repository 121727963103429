import SplunkOtelWeb from '@splunk/otel-web';
import getConfig from 'next/config';

const SplunkSPAAgent = (): void => {
  const { publicRuntimeConfig } = getConfig();
  const { SPLUNK_RUM_TOKEN, DEPLOY_ENVIRONMENT } = publicRuntimeConfig;

  const splunkEnviromentNaming = {
    local: 'Local',
    leda: 'Dev',
    titan: 'Staging',
    dark: 'Dark',
    prod: 'Prod'
  };

  if (!SplunkOtelWeb.inited) {
    SplunkOtelWeb.init({
      realm: 'us1',
      rumAccessToken: SPLUNK_RUM_TOKEN,
      applicationName: 'web',
      version: '0.1.0',
      deploymentEnvironment: splunkEnviromentNaming[DEPLOY_ENVIRONMENT as keyof typeof splunkEnviromentNaming] ?? 'Unknown'
    });
  }
};

export default SplunkSPAAgent;
