import gql from 'graphql-tag';
import ImageFragmentV3 from '../fragments/image.v3';
import LinkFragmentV3 from '../fragments/link.v3';
import TypedQuery from '../../types/TypedQuery';

export interface MarketingBannersResult {
  Homepage?: MarketingBanner;
}

const rawQuery = gql`
  query getMarketingBanners($envEnvironmentID: String!) {
    Homepage(envID: $envEnvironmentID) {
     marketingBanners {
        index
        image {
          ...ImageFragmentV3
        }
        link {
          ...LinkFragmentV3
        }
      }
    }
  }
  ${ImageFragmentV3}
  ${LinkFragmentV3}
`;
const parser = (data: MarketingBannersResult) : MarketingBanner => ({
  // @ts-expect-error: existing code
  marketingBanners: data?.Homepage?.marketingBanners
});

// @ts-expect-error: Expect type error for parser function
const query: TypedQuery<MarketingBanner> = { query: rawQuery, parser };
export default query;
