import getConfig from 'next/config';
import clientSide from '../common/isClientSide';
import NewRelicAPMAdapter from './adapters/newRelicAPM';
import { InstrumentationAdapter } from './adapters/types';
import SplunkTelemetryAdapter from './adapters/SplunkTelemetryAdapter';
import NewRelicBrowserAdapter from './adapters/newRelicBrowser';

class Telemetry {
  adapter: InstrumentationAdapter;

  constructor() {
    const envName = getConfig()?.publicRuntimeConfig.ENVIRONMENT_NAME;
    const useSplunk = envName === 'local' || envName === 'dev';
    if (useSplunk) {
      this.adapter = new SplunkTelemetryAdapter();
    } else {
      this.adapter = clientSide() ? new NewRelicBrowserAdapter() : new NewRelicAPMAdapter();
    }
  }

  addCustomEvent(name: string, attributes?: Record<string, unknown>) {
    try {
      this.adapter.addCustomEvent(name, attributes);
    } catch (e) {} // eslint-disable-line
  }

  addCustomAttribute(name: string, value: string) {
    try {
      this.adapter.addCustomAttribute(name, value);
    } catch (e) {} // eslint-disable-line
  }

  addNoticeError(error: Error, customAttributes?: Record<string, unknown>) {
    try {
      this.adapter.addNoticeError(error, customAttributes);
    } catch (e) {} // eslint-disable-line
  }

  addCustomEventOnClick(actionName: string) {
    return () => this.addCustomEvent(actionName);
  }
}

const telemetry = new Telemetry();
export default telemetry;
