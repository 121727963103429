import {
  Grow, MenuList, Paper, Popper, PopperPlacementType
} from '@material-ui/core';
import React, { MutableRefObject } from 'react';

const KEYBOARD_TAB_KEYCODE = 9;

interface DesktopPopperProps {
  open: boolean;
  testId: string;
  width?: string;
  placement: PopperPlacementType;
  items: JSX.Element[];
  handleClose: () => void;
  gridRef: MutableRefObject<any>;
  buttonRef: MutableRefObject<any>;
  categoryListRef: MutableRefObject<any>;
  classes: { [key: string]: string };
}

const DesktopPopper = ({
  open,
  testId,
  classes,
  width = 'auto',
  placement,
  items,
  handleClose,
  gridRef,
  buttonRef,
  categoryListRef
}: DesktopPopperProps) => {
  const popperStyle = {
    width: open ? width : 0,
    height: open ? 'auto' : 0,
    marginTop: '1px'
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Esc' || event.key === 'Escape') {
      event.preventDefault();
      handleClose();
      buttonRef.current.focus();
    }
    closePopperOnFocusAway(categoryListRef, event, handleClose);
  };

  return (
    <Popper
      open={open}
      style={popperStyle}
      placement={placement}
      transition
      keepMounted
      disablePortal
      onMouseLeave={handleClose}
      anchorEl={() => gridRef?.current}
      data-testid={`desktop-${testId}-popper`}
    >
      {
        ({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.menuListWrapper}>
              <MenuList
                id={`${testId}-list-grow`}
                ref={categoryListRef}
                disableListWrap
                className={classes.menuList}
                onKeyDown={handleListKeyDown}
              >
                {items}
              </MenuList>
            </Paper>
          </Grow>
        )
      }
    </Popper>
  );
};

export default DesktopPopper;

function isTabPressedOnLastElement(event: any, currentElement: any) {
  return event.target.innerText === currentElement.innerText;
}

function closePopperOnFocusAway(
  ref: MutableRefObject<Node>,
  event: any,
  handleClose: () => void
) {
  if (ref?.current?.hasChildNodes()) {
    const last: ChildNode | null = ref.current.lastChild;
    const isTabPressed = (event.key === 'Tab' || event.keyCode === KEYBOARD_TAB_KEYCODE);
    const isFocusedAway = isTabPressed && !event.shiftKey && isTabPressedOnLastElement(event, last);
    if (isFocusedAway) {
      handleClose();
    }
  }
}
