import { OrderState } from '@pizza-hut-us-development/client-core';
import { RootState } from '@pizza-hut-us-development/client-core/lib/esm/ReduxStore';
import { compose } from '@reduxjs/toolkit';
import { ContactlessStatus } from '@/localization/common/getContactlessStatus';

const domain = (state: RootState): OrderState => state.order;

const cartId = (order: OrderState) => order.cartId;
const cartType = (order: OrderState) => order?.cart?.type;
const cart = (order: OrderState) => order.cart;
const store = (order: OrderState) => order.store;
const allowsFutureOrders = (order: OrderState) => Boolean(order.store?.allowsFutureOrders);
const orderId = (order: OrderState) => order.orderId;
const currentOccasion = (order: OrderState) => order?.cart?.occasionId;
const contactlessPickupEnabled = (order: OrderState) => order.store?.metadata?.contactless === ContactlessStatus.PICKUP;
const contactlessCurbsideEnabled = (order: OrderState) => order.store?.metadata?.contactless === ContactlessStatus.CURBSIDE;
const tipValue = (order: OrderState) => order?.cart?.tip ?? 0;

export const orderSelectors = {
  cartId: compose(cartId, domain),
  cartType: compose(cartType, domain),
  cart: compose(cart, domain),
  store: compose(store, domain),
  allowsFutureOrders: compose(allowsFutureOrders, domain),
  orderId: compose(orderId, domain),
  currentOccasion: compose(currentOccasion, domain),
  contactlessPickupEnabled: compose(contactlessPickupEnabled, domain),
  contactlessCurbsideEnabled: compose(contactlessCurbsideEnabled, domain),
  tipValue: compose(tipValue, domain)
};
