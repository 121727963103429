export type CookiesEnum = 'CART_CC_ID';

export type CookieDetails = {
  key: string;
  expiresInMins: number;
};

export const COOKIES: Record<CookiesEnum, CookieDetails> = {
  CART_CC_ID: {
    key: 'cart_cc_id',
    expiresInMins: 45
  }
};
