export const MENU_CONTENT_KEY = 'Content';
export const MENU_FEATURE_FLAGS_KEY = 'FeatureFlags';
export const MENU_DEVELOPMENT_KEY = 'Development';
export const MENU_DIAGNOSTICS_KEY = 'Diagnostics';
export const MENU_TESTING_KEY = 'Testing';
export const INITIALIZATION_TIME_LIMIT = 5 * 1000; // time limit for iframe initialization
export const SIGNIN_IFRAME_WAIT_TIME = 10 * 1000;

// Cookie Names

export const ADVANCED_CART_DIAGNOSTICS_COOKIE_NAME = 'enable_advanced_cart_diagnostics';

export const CC_LOCALIZATION = 'cc_localization';
export const CHECKOUT_FORM_TIME_OUT = 'checkout_form_time_out';
export const CMS_ENV_ID = 'cms_env_id';

export const DISABLE_GQL_SSR = 'disable_gql_ssr';
export const DISABLE_GQL_CLIENT_SIDE_DECORATION = 'disable_gql_client_side_decoration';

export const ENABLE_GOOGLE_TAG_MANAGER = 'enable_google_tag_manager';

export const GQL_URL = 'gql_url';
export const GQL_VERSION = 'gql_version';
export const GUEST_TOKEN_REFRESH_INTERVAL_COOKIE_NAME = 'guest_token_refresh_interval';

export const SALES_EXPERIENCE = 'Sales-Experience';
export const SHOULD_MOCK_CLIENTS = 'SHOULD_MOCK_CLIENTS';

export const UA_CHANNEL_COOKIE_KEY = 'UA_channel';
export const UATOKEN_EXPIRATION_TIME_COOKIE_NAME = 'uatoken_expiration_time';

export const YUM_GUEST_TOKEN_COOKIE_NAME = 'yum_guest_token';
export const YUM_GUEST_REFRESH_COOKIE_NAME = 'yum_guest_refresh';
export const YUM_GUEST_EXPIRY_COOKIE_NAME = 'yum_guest_expiration';

export const YUM_TOKEN_COOKIE_KEY = 'Yum_token';
export const YUM_REFRESH_TOKEN_COOKIE_KEY = 'Yum_refresh_token';
export const YUM_ID_TOKEN_COOKIE_KEY = 'Yum_id_token';

export const YUM_PAYMENT_REFRESH_TOKEN_COOKIE_KEY = 'Yum_payment_refresh_token';
