import { useDispatch, useSelector } from 'react-redux';
import clientCore from '@pizza-hut-us-development/client-core/package.json';
import { CoreConfigActions } from '@pizza-hut-us-development/client-core';
import { useRouter } from 'next/router';
import { RootState } from '@/rootStateTypes';
import useGetVersion from '@/configuration/components/diagnostics/hooks/useGetVersion';
import { getOrInitializeOptimizely } from '../../optimizely/optimizely';
import {
  setCCDebugEnabled,
  setCCDebugMode,
  setForceNonYumMode
} from '@/clientCore/redux/debug/DebugSlice';
import { DebugTypes } from '@/clientCore/redux/debug/types';

/* eslint-disable no-console */
type WindowFunctionsProperties = {
  ph: {
    debug: () => Record<string, any>;
    ccDebug: () => Record<string, any>;
    ccDebugMode: (enabled: boolean, mode: DebugTypes) => void;
    help: () => string;
    isFlagEnabled: (flagName: string) => string;
    route: (relativePath: string) => string;
    setPreviewMode: (isEnabled: boolean) => string;
    forceNonYum: () => void;
  };
};

type WindowFunctions = WindowFunctionsProperties & Window & typeof globalThis;

const useWindowFunctions = () => {
  const version = useGetVersion();
  const router = useRouter();
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const isPreviewMode = useSelector((state: RootState) => state.coreConfig.isPreviewMode);

  const dispatch = useDispatch();
  // Validate that we have a window to attach to
  if (typeof window === 'undefined') {
    return;
  }

  // Returns general debugging info
  const debug = () => ({
    version
  });

  // Returns debugging info related to Client Core adoption
  const ccDebug = () => ({
    ccVersion: clientCore.version,
    isYum: isYumEcomm,
    isPreviewMode
  });

  const ccDebugMode = (enable = true, mode: DebugTypes = 'all', ccDebuggerEnabled = false) => {
    const potentialModes = ['all', 'transformations', 'menu', 'products', 'cart', 'edit', 'deals', 'site'];
    const { setDebugMode } = CoreConfigActions;
    if (potentialModes.find((pMode) => pMode === mode)) {
      // setting ccCoreProvider
      dispatch(setDebugMode(ccDebuggerEnabled));
      // setting Web's redux
      dispatch(setCCDebugEnabled({ isEnabled: enable }));
      dispatch(setCCDebugMode({ debugMode: mode }));
      return `ccDebugMode enabled: ${enable} mode: ${mode}`;
    }
    console.log('Invalid mode selected, please try again using one of the modes below:');
    return "modes: 'all','transformations','menu','products','cart','edit','deals','site'";
  };

  const help = () => {
    console.log('Commands Available:');
    console.log('ph.debug(), ph.ccDebug(), ph.ccDebugMode(), ph.isFlagEnabled(flagName), ph.setPreviewMode(), ph.forceNonYum()');
    // returning the last since the console will show undefined if not returning anything.
    return 'Link for info on use: https://pizzahut.atlassian.net/wiki/spaces/WE/pages/5724274825/Web+Console+Methods+ph+methods';
  };

  const forceNonYum = (isEnabled = true) => {
    dispatch(setForceNonYumMode({ forceNonYum: isEnabled }));
    return `forceNonYum - ${isEnabled}`;
  };

  const isFlagEnabled = (flagName: string) => {
    const optimizely = getOrInitializeOptimizely();
    return `flagName: ${optimizely?.isFeatureEnabled(flagName) ?? false}`;
  };

  const route = (relativePath: string) => {
    if (relativePath[0] !== '/') return 'Not a valid relativePath';
    router.push(relativePath);
    return `Routing to ${relativePath}`;
  };

  const setPreviewMode = (isEnabled = true) => {
    dispatch(CoreConfigActions.setIsPreviewMode(isEnabled));
    return `setIsPreviewMode - ${isEnabled}`;
  };

  // Hook up our functions to the window
  (window as WindowFunctions).ph = {
    debug,
    ccDebug,
    ccDebugMode,
    help,
    isFlagEnabled,
    route,
    setPreviewMode,
    forceNonYum
  };
};

export default useWindowFunctions;
