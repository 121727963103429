import phdApiClient, { DealPrivateCodeResult } from '../../api/phdApiClient';
import { DealService, DoesStoreOfferDealResult } from '@/services/dealService/types';

const dealService: DealService = {
  doesStoreOfferDeal: (
    storeID: string, dealName: string, localizationToken: string
  ): Promise<DoesStoreOfferDealResult> => {
    const coupon = dealName;
    return phdApiClient.checkCouponCode(storeID, coupon, localizationToken);
  },
  getPrivateCode(
    storeID: string, dealName: string, localizationToken: string
  ): Promise<DealPrivateCodeResult> {
    return phdApiClient.getPrivateCode(storeID, dealName, localizationToken);
  }
};

export default dealService;
