import { Categories, Product } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import {
  extractFromOptions,
  extractYumPastaAdditionalOption,
  getDefaultOption,
  getMatchedSizes,
  shapeOneClickDefaults,
  wingsAdditionalOptionsFromCCData,
  wingsAdditionalOptionsFromCCDataWithExcludingVariants
} from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts/helpers/menuProductsData';
import { AvailabilityItem } from '@/graphql/helpers/checkAvailability';
import { DisplayableAdditionalOptions, DisplayableProduct } from '@/domain/product/types';

export const transformProductLegacy = (availabilityProduct: AvailabilityItem<Product>): DisplayableProduct => {
  const isWings = availabilityProduct.name?.toLowerCase().includes('wing');
  const isPasta = availabilityProduct.id.includes('CLSS060PS');

  let additionalOptions: DisplayableAdditionalOptions | undefined;
  let categoryId: Categories | undefined;

  if (isWings) {
    additionalOptions = wingsAdditionalOptionsFromCCData(availabilityProduct);
    categoryId = Categories.WINGS;
  } else if (isPasta) {
    additionalOptions = extractYumPastaAdditionalOption(availabilityProduct);
  }

  return ({
    additionalOptions: additionalOptions ?? extractFromOptions(availabilityProduct),
    available: availabilityProduct.available,
    description: availabilityProduct.description || '',
    displayOrder: availabilityProduct.displayOrder,
    id: availabilityProduct.id,
    imageUrl: availabilityProduct.imageURL || '',
    isOutOfStock: availabilityProduct.outOfStock,
    name: availabilityProduct.name,
    preSelectedAdditionalOption: getDefaultOption(
      availabilityProduct.defaultSelectedOption
    ),
    priority: 0,
    sizes: getMatchedSizes(availabilityProduct, categoryId),
    sodiumWarning: availabilityProduct.sodiumWarning,
    type: availabilityProduct.type || '',
    ...(availabilityProduct.type === 'MELT' && {
      oneClickDefaults: shapeOneClickDefaults(availabilityProduct)
    })
  });
};

export const transformProductWithExcludingVariants = (availabilityProduct: AvailabilityItem<Product>): DisplayableProduct => {
  const isWings = availabilityProduct.name?.toLowerCase().includes('wing');
  const isPasta = availabilityProduct.id.includes('CLSS060PS');

  let additionalOptions: DisplayableAdditionalOptions | undefined;
  let categoryId: Categories | undefined;

  if (isWings) {
    additionalOptions = wingsAdditionalOptionsFromCCDataWithExcludingVariants(availabilityProduct);
    categoryId = Categories.WINGS;
  } else if (isPasta) {
    additionalOptions = extractYumPastaAdditionalOption(availabilityProduct);
  }

  return ({
    additionalOptions: additionalOptions ?? extractFromOptions(availabilityProduct),
    available: availabilityProduct.available,
    description: availabilityProduct.description || '',
    displayOrder: availabilityProduct.displayOrder,
    id: availabilityProduct.id,
    imageUrl: availabilityProduct.imageURL || '',
    isOutOfStock: availabilityProduct.outOfStock,
    name: availabilityProduct.name,
    preSelectedAdditionalOption: getDefaultOption(
      availabilityProduct.defaultSelectedOption
    ),
    priority: 0,
    sizes: getMatchedSizes(availabilityProduct, categoryId),
    sodiumWarning: availabilityProduct.sodiumWarning,
    type: availabilityProduct.type || '',
    ...(availabilityProduct.type === 'MELT' && {
      oneClickDefaults: shapeOneClickDefaults(availabilityProduct)
    })
  });
};

const useCCGetDisplayableProduct = () => {
  const [{ enabled: shouldFilterExcludedVariants }] = useDecision('fr-dtg-honor-excluded-variants-attribute-wings');
  const transformProduct = shouldFilterExcludedVariants ? transformProductWithExcludingVariants : transformProductLegacy;
  return { transformProduct };
};

export default useCCGetDisplayableProduct;
