import gql from 'graphql-tag';
import { SeoPage, SeoQuery, SeoResult } from '../../types/seo';

const rawQuery = gql`
  query getSEO($envEnvironmentID: String!, $pageID: String) {
    Homepage(envID: $envEnvironmentID) {
      seo {
        page(key: $pageID) {
          title
          description
        }
      }
    }
  }
`;

const parser = (data: SeoResult): SeoPage => ({
  seo: data?.Homepage?.seo?.page
});

// @ts-expect-error: Expect type error for parser function
const query: SeoQuery = { query: rawQuery, parser };
export default query;
