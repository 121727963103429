import { Placement, Portion } from '@pizza-hut-us-development/client-core';
import { ItemWithModifier } from '@/graphql/types/Category';
import {
  AddableCartItemKinds
} from '@/api/phdApiV2Client/request.types';
import { PIZZA, DISCOUNT, NO_TRANSFORM_TYPES } from '@/domain/constants';

export type DisplayableAdditionalOptions = Record<
string,
DisplayableModifier[]
>;
export interface DisplayableProduct {
  id: string;
  available?: boolean;
  name: string;
  imageUrl: string;
  description: string;
  upcharge?: number;
  type: string;
  isOutOfStock?: boolean;
  sodiumWarning?: boolean;
  displayOrder?: number;
  sizes?: DisplayableModifier[];
  additionalOptions?: DisplayableAdditionalOptions;
  priority?: number;
  preSelectedAdditionalOption?: DisplayableModifier;
  oneClickDefaults?: ProductModifier[];
  price?: number;
}

export interface DisplayablePizzaProduct
  extends DisplayableProduct {
  price: number;
}

export type DisplayableMenuItem = DisplayableProduct | DisplayablePizzaProduct;

export const isPizzaProduct = (
  product: DisplayableMenuItem | ItemWithModifier
): product is DisplayablePizzaProduct => product.type === PIZZA;

export const isOneClickDefaultsProduct = (
  product: DisplayableMenuItem | ItemWithModifier
): product is DisplayableProduct => (product.type ? NO_TRANSFORM_TYPES.includes(product.type) : false);

const isDealProduct = (
  product: DisplayableMenuItem | ItemWithModifier
): product is DisplayableProduct & { type: 'DISCOUNT' } => product.type === DISCOUNT;

export const isDisplayableProduct = (
  product: DisplayableMenuItem | ItemWithModifier
): product is DisplayableProduct & { type: 'PRODUCT' } => !isPizzaProduct(product) && !isDealProduct(product);

export interface DisplayableProductWithSubcategory extends DisplayableProduct {
  subcategory: string;
}

export interface DisplayableModifier {
  id: string;
  name: string;
  type: string;
  price?: number;
  priority?: number;
  nutrition: DisplayableNutritionInfo[];
  modifiers: DisplayableModifier[];
  isOutOfStock?: boolean;
  sodiumWarning?: boolean;
  selected?: boolean;
  placement?: Placement;
  portion?: Portion;
  variantCode?: string;
  slotCode?: string;
  weightCode?: string;
  excludedVariantList?: string[];
  upcharge?: number;
}

export interface DisplayableNutritionInfo {
  calories: number;
  servings: string;
  unit: string;
  name: string;
}

export interface Product {
  kind: AddableCartItemKinds.PRODUCT;
  type: string;
  id?: string;
  name: string;
  description?: string;
  quantity: number;
  selectedModifiers: ProductModifier[];
  sodiumWarning?: boolean;
  options?: {
    Sizes: DisplayableModifier[];
    [key: string]: DisplayableModifier[];
  };
  outOfStock?: boolean;
  variantCode?: string;
}

export interface ProductModifier {
  id: string;
  name: string;
  type: string;
  sodiumWarning?: boolean;
  modifiers?: ProductModifier[];
  quantities?: number[];
  placement?: Placement;
  portion?: Portion;
  variantCode?: string;
  slotCode?: string;
  weightCode?: string;
  nutrition?: Nutrition[];
  outOfStock?: boolean;
}
