import React from 'react';
import { Card } from '@material-ui/core';
import DealLink from '../../common/DealLink';
import ResponsiveImageRender from '../../common/ResponsiveImageRender';
import {
  onInitialHomepagePageLoadPromoInView,
  onPromoClick
} from '../../dataAnalytics/dataAnalyticsHelper';
import useWindowSize, { isMobile } from '../../common/useWindowSize';
import useHomepageHero from './useHomepageHero';
import telemetry from '../../telemetry';
import mapObjectToAddPrefix from '../../common/mapObjectToAddPrefix';
import styles from './HomepageHero.styles';
import useDeepEffect from '../../common/useDeepEffect';
import { PromoAnalytics } from '@/dataAnalytics/analyticsTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

function HomepageHero(): JSX.Element {
  const classes = styles();
  const mobileSize = isMobile(useWindowSize());
  const homepage = useHomepageHero();
  const analytics = useAnalytics();

  useDeepEffect(() => {
    if (homepage.pushInViewAnalytics) {
      analytics.push(() => onInitialHomepagePageLoadPromoInView('Hero', createPromo(homepage.hero, mobileSize)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homepage.pushInViewAnalytics]);

  if (homepage.hasNoHero) {
    return <div data-testid="no-homepage-hero" />;
  }


  const { image, link } = homepage.loading ? skeletonHeroImage() : homepage.hero;
  const testId = !homepage.loading ? 'homepage-hero-link' : 'homepage-hero-link-loading';

  const pushPromoAnalyticsOnClick = (
    hero: Hero | null
  ) => {
    telemetry.addCustomEvent('homepage-hero-click');
    analytics.push(() => onPromoClick('Hero', createPromo(hero, mobileSize)));
  };

  return (
    <div className={classes.heroContainer}>
      <DealLink
        className={classes.heroWrapper}
        testId={testId}
        dataAnalyticsCategory="hero"
        dataAnalyticsAction={link?.link}
        linkRef={homepage.ref}
        tabIndex={0}
        {...link}
      >
        <Card
          onClick={() => pushPromoAnalyticsOnClick(homepage.hero)}
          data-testid="homepage-hero-card"
          className={classes.heroCard}
        >
          <ResponsiveImageRender
            testId="mobile-picture-source"
            className={classes.hero}
            loading={homepage.loading}
            loadingAnimation="wave"
            {...image}
          />
        </Card>
      </DealLink>
    </div>
  );
}

function skeletonHeroImage(): Hero {
  return ({
    image: {
      mobile: '/images/placeholder-image.png',
      desktop: '/images/placeholder-image.png',
      altText: 'loading',
      title: 'loading'
    },
    link: {
      external: false,
      link: '',
      linkDisplayText: '',
      linkTitle: '',
      linkType: ''
    }
  });
}

function createPromo(hero: Hero | null, mobileSize: boolean): PromoAnalytics {
  return mapObjectToAddPrefix({
    name: hero?.image.title,
    creative: mobileSize ? hero?.image.mobile : hero?.image.desktop,
    type: 'hero',
    position: 'Hero Image Slide 1',
    id: hero?.heroID
  }, 'promo') as PromoAnalytics;
}

export default HomepageHero;
