import React, { useRef } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Product } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { OneClickUpsellCartRailRowSkeleton } from '../OneClickUpsellCartRailRow/OneClickUpsellCartRailRowSkeleton';
import OneClickUpsellCartRailRow from '../OneClickUpsellCartRailRow/OneClickUpsellCartRailRow';

import { useOneClickUpsellCart } from '../../hooks/useOneClickUpsellCart';
import useOneClickUpsellCartRailListStyles from './styles';
import { onOneClickCartUpsellTreated, onOneClickCartUpsellUnTreated } from '@/dataAnalytics/upsell/upsellAnalytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const OneClickUpsellCartRailList = (): JSX.Element | null => {
  const classes = useOneClickUpsellCartRailListStyles();
  const {
    products,
    isUpsellQueryLoading
  } = useOneClickUpsellCart();

  const hasProducts = products && products.length > 0;
  const [{ enabled: isOneClickCartUpsellTrackingEnabled }] = useDecision('fr-dtg-1216-cart_upsell_tracking');
  const isAnalyticsPushed = useRef(false);
  const analytics = useAnalytics();

  const pushTreatedAnalytics = !isUpsellQueryLoading && hasProducts && !isAnalyticsPushed.current && isOneClickCartUpsellTrackingEnabled;
  if (pushTreatedAnalytics) {
    analytics.push(() => onOneClickCartUpsellTreated());
    isAnalyticsPushed.current = true;
  }

  const pushUntreatedAnalytics = !isUpsellQueryLoading && !hasProducts && !isAnalyticsPushed.current && isOneClickCartUpsellTrackingEnabled;
  if (pushUntreatedAnalytics) {
    analytics.push(() => onOneClickCartUpsellUnTreated());
    isAnalyticsPushed.current = true;
    return null;
  }

  return (
    <Grid
      container
      data-testid="one-click-upsell-cart-rail-list"
      justifyContent="space-between"
      alignItems="center"
      className={hasProducts ? classes.root : ''}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerItemContainer}
      >
        <Grid item className={classes.titleContainer}>
          <Typography variant="h2" className={classes.title}>
            Recommended for you
          </Typography>
        </Grid>
        <Grid container direction="column" spacing={3} component="ul" className={classes.upsellsContainer}>
          {
            isUpsellQueryLoading
              ? [1, 2, 3].map((_, idx) => (
                <li key={`one-click-upsell-skeleton-${idx}`} data-testid="one-click-upsell-cart-skeleton">
                  <OneClickUpsellCartRailRowSkeleton />
                  <Divider className={classes.divider} aria-hidden="true" />
                </li>
              ))
              : products.map((product, index) => (
                <li key={product.id}>
                  <OneClickUpsellCartRailRow index={index} product={product as Product} />
                  <Divider className={classes.divider} aria-hidden="true" />
                </li>
              ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OneClickUpsellCartRailList;
