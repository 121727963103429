import { useEffect, useRef, useState } from 'react';
import {
  useRemovePromoCodeMutation,
  useApplyLoyaltyOfferMutation,
  LoyaltyOfferOperation,
  PromotionDefinition,
  orderApiCart,
  AppliedPromotion,
  PromotionNotAppliedWarning
} from '@pizza-hut-us-development/client-core';
import { useDispatch, useSelector } from 'react-redux';
import telemetry from '@/telemetry';
import useCodeRedemption from './useCodeRedemption';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { isResolvableWarning } from '../helpers';

export type YumAddedCouponProps = {
  promotionOrWarning: AppliedPromotion | PromotionNotAppliedWarning;
};

type UseYumAddedCouponType = [
  { name: string | undefined; warningMessage: JSX.Element | null; shouldDisplay: boolean },
  { handleRemoveYumPromoCode: (code?: string, loyaltyOfferId?: string) => Promise<void> }
];

const useYumAddedCoupons = ({ promotionOrWarning }: YumAddedCouponProps): UseYumAddedCouponType => {
  const [removePromoCode] = useRemovePromoCodeMutation();
  const [applyLoyaltyOffer] = useApplyLoyaltyOfferMutation();

  const dispatch = useDispatch();
  const [handleRedemptionWarnings] = useCodeRedemption();
  const cart = useSelector(orderSelectors.cart);

  const [name, setName] = useState<string>();
  const [warningMessage, setWarningMessage] = useState<JSX.Element | null>(null);

  const isAppliedPromotion = (item: AppliedPromotion | PromotionNotAppliedWarning): item is AppliedPromotion => 'name' in item;

  // This prevents the handleRedemptionWarnings from firing off twice, since it's async
  const handlerHasRun = useRef(false);
  useEffect(() => {
    // TEMP: Until CC has promotion definition bundled with cart responses
    const getPromotionInfo = async (promotionId: string) => {
      const response = await dispatch(orderApiCart.endpoints.getPromotion.initiate(promotionId)) as unknown as { data: PromotionDefinition };
      const promotionDefinition = response?.data;
      if (!name) {
        setName(promotionDefinition?.displayName || 'Applied promotion');
      }
      if (!handlerHasRun.current) {
        handlerHasRun.current = true;
        const warningText = await handleRedemptionWarnings(promotionId, cart, promotionDefinition);

        if (!warningMessage) {
          setWarningMessage(warningText);
        }
      }
    };

    if (isAppliedPromotion(promotionOrWarning)) {
      setName(promotionOrWarning.name);
      setWarningMessage(null);
    } else {
      getPromotionInfo(promotionOrWarning.promotionId);
    }
  }, [name, warningMessage, dispatch, promotionOrWarning, cart, handleRedemptionWarnings]);

  const handleRemoveYumPromoCode = async () => {
    let code: string | undefined; let loyaltyOfferId: string | undefined;
    if (isAppliedPromotion(promotionOrWarning)) {
      code = promotionOrWarning.code;
      loyaltyOfferId = promotionOrWarning.loyaltyOfferIds?.[0];
    } else {
      code = promotionOrWarning.code;
      loyaltyOfferId = promotionOrWarning.loyaltyOffer?.loyaltyOfferId;
    }
    if (!code && !loyaltyOfferId) return;
    try {
      // If promo is associated with a loyalty offer, we have to remove the loyalty offer instead
      if (loyaltyOfferId) {
        await applyLoyaltyOffer({
          loyaltyOfferId,
          operation: LoyaltyOfferOperation.REMOVE
        });
      } else if (code) {
        await removePromoCode(code);
      }
    } catch (error) {
      telemetry.addNoticeError(new Error('Error removing promotion from cart'));
    }
  };

  const shouldDisplay = isAppliedPromotion(promotionOrWarning) || isResolvableWarning(promotionOrWarning);

  return [
    { name, warningMessage, shouldDisplay },
    { handleRemoveYumPromoCode }
  ];
};

export default useYumAddedCoupons;
