import {
  DealBuilder,
  DealBuilderStep,
  Rule as CCRule,
  Availability,
  DealBuilderRecipe,
  transformYumPizzaProductToPizzaBuilder,
  ItemType
} from '@pizza-hut-us-development/client-core';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import DealType from '@/builders/deals/DealTypes';
import {
  DealData,
  DealRecipe,
  DealRuleOption,
  DealStep
} from '@/builders/deals/slice/dealTypes';
import checkAvailability from '@/graphql/helpers/checkAvailability';
import { OccasionApi } from '@/localization/constants';
import { IsCyo } from '@/menu/pizza/pizzaMenuTypes';
import { DisplayableProduct } from '@/domain/product/types';
import { transformProductLegacy, transformProductWithExcludingVariants } from '@/clientCore/temporaryTransformationalHooks/useCCGetDisplayableProduct';
import { transformPizzaBuilderPizza } from '@/clientCore/temporaryTransformationalHooks/useCCGetPizzaBuilderQuery/transformPizzaBuilderPizza';
import decodeEntities from '@/clientCore/helper/decodeEntities';
import { STEP } from '@/domain/constants';
import { checkSodiumWarning } from '@/clientCore/helper/checkSodiumWarning';

type RuleSet = { stepId: string; rulesByStepId: CCRule[] };
type StepSet = { transformedSteps: DealStep[]; extractedRules: RuleSet[] };

const transformRecipeToDisplayableProduct = (
  recipe: DealBuilderRecipe,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): DisplayableProduct => {
  const availabilityProduct = checkAvailability(
    recipe,
    occasion,
    storeTimeZone,
    isYumEcomm
  );
  return transformProductLegacy(availabilityProduct);
};

const transformRecipeToDisplayableProductWithExcludingVariants = (
  recipe: DealBuilderRecipe,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): DisplayableProduct => {
  const availabilityProduct = checkAvailability(
    recipe,
    occasion,
    storeTimeZone,
    isYumEcomm
  );
  return transformProductWithExcludingVariants(availabilityProduct);
};

const transformRecipeToPizzaBuilderOptions = (recipe: DealBuilderRecipe,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean) => {
  // TODO: Shouldn't have to do this - client core should transform from Pizza to PizzaBuilder before returning as with useGetPizzaBuilderQuery
  const pizzaBuilder = transformYumPizzaProductToPizzaBuilder(recipe);
  return transformPizzaBuilderPizza(pizzaBuilder, occasion, storeTimeZone, isYumEcomm);
};

const transformRecipes = (
  recipes: DealBuilderRecipe[],
  step: DealBuilderStep,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): DealRecipe[] => recipes.map(
  (recipe): DealRecipe => {
    const isMeltOrPizza = recipe.type === 'PIZZA' || recipe.type === 'MELT';
    return {
      customizationsIncluded: recipe.customizationsIncluded ?? 0,
      isCYO: recipe.isCYO ? IsCyo.TRUE : IsCyo.FALSE,
      id: recipe.id.slice(recipe.id.lastIndexOf('/') + 1, recipe.id.length),
      name: recipe.name,
      type: recipe?.type ?? '',
      price: recipe?.price ?? null,
      imageURL: recipe?.imageURL ?? null,
      fullId: recipe.id,
      priority: recipe.displayOrder,
      outOfStock: recipe?.outOfStock ?? false,
      description: recipe.description,
      sodiumWarning: recipe?.sodiumWarning || checkSodiumWarning(recipe?.selectedOptions ?? []),
      imageAltText: '',
      staticLink: '',
      availability: [],
      hidden: false,
      ...(isYumEcomm && !isMeltOrPizza
        ? {
          displayableProduct: transformRecipeToDisplayableProduct(
            recipe,
            occasion,
            storeTimeZone,
            isYumEcomm
          )
        }
        : undefined),
      ...(isYumEcomm && isMeltOrPizza
        ? {
          pizzaBuilderOptions: transformRecipeToPizzaBuilderOptions(
            recipe,
            occasion,
            storeTimeZone,
            isYumEcomm
          )
        }
        : undefined)
    };
  }
);

const transformRecipesWithExcludingVariants = (
  recipes: DealBuilderRecipe[],
  step: DealBuilderStep,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): DealRecipe[] => recipes.map(
  (recipe): DealRecipe => {
    const isMeltOrPizza = recipe.type === 'PIZZA' || recipe.type === 'MELT';
    return {
      customizationsIncluded: recipe.customizationsIncluded ?? 0,
      isCYO: recipe.isCYO ? IsCyo.TRUE : IsCyo.FALSE,
      id: recipe.id.slice(recipe.id.lastIndexOf('/') + 1, recipe.id.length),
      name: recipe.name,
      type: recipe?.type ?? '',
      price: recipe?.price ?? null,
      imageURL: recipe?.imageURL ?? null,
      fullId: recipe.id,
      priority: recipe.displayOrder,
      outOfStock: recipe?.outOfStock ?? false,
      description: recipe.description,
      sodiumWarning: recipe?.sodiumWarning || checkSodiumWarning(recipe?.selectedOptions ?? []),
      imageAltText: '',
      staticLink: '',
      availability: [],
      hidden: false,
      ...(isYumEcomm && !isMeltOrPizza
        ? {
          displayableProduct: transformRecipeToDisplayableProductWithExcludingVariants(
            recipe,
            occasion,
            storeTimeZone,
            isYumEcomm
          )
        }
        : undefined),
      ...(isYumEcomm && isMeltOrPizza
        ? {
          pizzaBuilderOptions: transformRecipeToPizzaBuilderOptions(
            recipe,
            occasion,
            storeTimeZone,
            isYumEcomm
          )
        }
        : undefined)
    };
  }
);

const transformStepsExtractRules = (
  ccSteps: DealBuilderStep[],
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): StepSet => ccSteps
  .reduce(
    (acc: StepSet, currentStep) => {
      const { transformedSteps, extractedRules } = acc;
      // TODO: Remove the check !isYumEcomm, when required field comes as true for YUM stores.
      if (!isYumEcomm && !currentStep.required && currentStep.type === STEP) return acc;
      const newStep = {
        id: currentStep.id,
        name: decodeEntities(currentStep.name),
        price: currentStep.price,
        required: currentStep.required,
        displayText: currentStep.displayText ?? '',
        description: decodeEntities(currentStep.description),
        recipeOptions: transformRecipes(
          currentStep.recipes,
          currentStep,
          occasion,
          storeTimeZone,
          isYumEcomm
        ), // will transform
        outOfStock: currentStep.outOfStock,
        allergenDisclaimer: currentStep.allergenDisclaimer ?? undefined,
        image: currentStep.imageURL ?? undefined
      };

      return {
        transformedSteps: [...transformedSteps, newStep],
        extractedRules: [
          ...extractedRules,
          { stepId: currentStep.id, rulesByStepId: [...currentStep.rules] }
        ]
      };
    },
    { transformedSteps: [], extractedRules: [] }
  );

const transformStepsExtractRulesWithExcludingVariants = (
  ccSteps: DealBuilderStep[],
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): StepSet => ccSteps
  .reduce(
    (acc: StepSet, currentStep) => {
      const { transformedSteps, extractedRules } = acc;
      // TODO: Remove the check !isYumEcomm, when required field comes as true for YUM stores.
      if (!isYumEcomm && !currentStep.required && currentStep.type === STEP) return acc;
      const newStep = {
        id: currentStep.id,
        name: decodeEntities(currentStep.name),
        price: currentStep.price,
        required: currentStep.required,
        displayText: currentStep.displayText ?? '',
        description: decodeEntities(currentStep.description),
        recipeOptions: transformRecipesWithExcludingVariants(
          currentStep.recipes,
          currentStep,
          occasion,
          storeTimeZone,
          isYumEcomm
        ), // will transform
        outOfStock: currentStep.outOfStock,
        allergenDisclaimer: currentStep.allergenDisclaimer ?? undefined,
        image: currentStep.imageURL ?? undefined
      };

      return {
        transformedSteps: [...transformedSteps, newStep],
        extractedRules: [
          ...extractedRules,
          { stepId: currentStep.id, rulesByStepId: [...currentStep.rules] }
        ]
      };
    },
    { transformedSteps: [], extractedRules: [] }
  );

const ruleBoilerPlate: DealRuleOption = {
  PRICING: [],
  ADD: [],
  RESTRICT: [],
  DELETE: [],
  INCLUDE: []
};

const transformRules = (ccRules: RuleSet[]): DealRuleOption => ccRules.reduce((acc: DealRuleOption, currentRuleSet) => {
  const { stepId, rulesByStepId } = currentRuleSet;
  const newRules = rulesByStepId.reduce(
    (rulesObjPerIndex, untransformedRule) => {
      const { action, actionItem } = untransformedRule;
      const newTransformedRule = {
        ...actionItem,
        rule: action,
        stepId
      };
      const newRuleSet = {
        PRICING: [...(rulesObjPerIndex.PRICING || [])],
        ADD: [...(rulesObjPerIndex.ADD || [])],
        RESTRICT: [...(rulesObjPerIndex.RESTRICT || [])],
        DELETE: [...(rulesObjPerIndex.DELETE || [])],
        INCLUDE: [...(rulesObjPerIndex.INCLUDE || [])]
      };
      newRuleSet[action].push(newTransformedRule);
      return newRuleSet;
    },
    ruleBoilerPlate
  );

  return {
    PRICING: [...(acc.PRICING || []), ...(newRules.PRICING || [])],
    ADD: [...(acc.ADD || []), ...(newRules.ADD || [])],
    RESTRICT: [...(acc.RESTRICT || []), ...(newRules.RESTRICT || [])],
    DELETE: [...(acc.DELETE || []), ...(newRules.DELETE || [])],
    INCLUDE: [...(acc.INCLUDE || []), ...(newRules.INCLUDE || [])]
  };
}, ruleBoilerPlate);

export type DealBuilderWithAvailability = DealBuilder & {
  availability: Availability[];
};

export const transformGetDealBuilderData = (
  data: DealBuilderWithAvailability,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): DealData => {
  const {
    id,
    name,
    imageURL,
    steps,
    outOfStock,
    available,
    availableInOtherOccasion,
    combinableDiscount,
    legalText
  } = checkAvailability(data, occasion, storeTimeZone, isYumEcomm);

  const { transformedSteps, extractedRules } = transformStepsExtractRules(
    steps,
    occasion,
    storeTimeZone,
    isYumEcomm
  );

  const getDealType = (type: ItemType): DealType => {
    switch (type) {
      case DealType.PARENT:
        return DealType.PARENT;
      default:
        if (transformedSteps.length > 1) return DealType.MULTI_STEP;
        if (transformedSteps.length === 1) return DealType.SINGLE_STEP;
        return DealType.ORDER_LEVEL;
    }
  };

  const transformedDealPayload = {
    id,
    name,
    image: imageURL ?? '',
    type: getDealType(data.type),
    kind: AddableCartItemKinds.DEAL as AddableCartItemKinds.DEAL,
    steps: transformedSteps,
    outOfStock: Boolean(outOfStock),
    available,
    availableInOtherOccasion,
    rules: transformRules(extractedRules),
    combinable: Boolean(combinableDiscount),
    legalText,
    price: data.price
  };

  return transformedDealPayload;
};

export const transformGetDealBuilderDataWithExcludingVariants = (
  data: DealBuilderWithAvailability,
  occasion: OccasionApi,
  storeTimeZone: string,
  isYumEcomm: boolean
): DealData => {
  const {
    id,
    name,
    imageURL,
    steps,
    outOfStock,
    available,
    availableInOtherOccasion,
    combinableDiscount,
    legalText
  } = checkAvailability(data, occasion, storeTimeZone, isYumEcomm);

  const { transformedSteps, extractedRules } = transformStepsExtractRulesWithExcludingVariants(
    steps,
    occasion,
    storeTimeZone,
    isYumEcomm
  );

  const getDealType = (type: ItemType): DealType => {
    switch (type) {
      case DealType.PARENT:
        return DealType.PARENT;
      default:
        if (transformedSteps.length > 1) return DealType.MULTI_STEP;
        if (transformedSteps.length === 1) return DealType.SINGLE_STEP;
        return DealType.ORDER_LEVEL;
    }
  };

  const transformedDealPayload = {
    id,
    name,
    image: imageURL ?? '',
    type: getDealType(data.type),
    kind: AddableCartItemKinds.DEAL as AddableCartItemKinds.DEAL,
    steps: transformedSteps,
    outOfStock: Boolean(outOfStock),
    available,
    availableInOtherOccasion,
    rules: transformRules(extractedRules),
    combinable: Boolean(combinableDiscount),
    legalText,
    price: data.price
  };

  return transformedDealPayload;
};
