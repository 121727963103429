import { useEffect, useRef } from 'react';

const useUnload = (fn: (args?: unknown) => unknown): void => {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args: unknown[]) => cb.current?.(...args);

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};

export default useUnload;
