import React, { MouseEventHandler }  from 'react';
import {
  Button, makeStyles, createStyles
} from '@material-ui/core';
import CaratIcon from '../../localization/icons/CaratIcon';
import fontStyles from '../fontStyles';

const useStyles = makeStyles(() => createStyles({
  ctaButton: {
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '1.43',
    padding: '0px',
    float: 'right',
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent'
    }
  },
  ctaText: {
    ...fontStyles.redTextLink,
    marginRight: '5px',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

interface TextLinkWithCaratProps {
  testId: string;
  ariaLabel: string;
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const TextLinkWithCarat = (props: TextLinkWithCaratProps) => {
  const {
    testId, ariaLabel, text, onClick
  } = props;
  const classes = useStyles();

  return (
    <Button
      data-testid={testId}
      aria-label={ariaLabel}
      className={classes.ctaButton}
      onClick={onClick}
      disableRipple
    >
      <span className={classes.ctaText}>{text}</span>
      <CaratIcon />
    </Button>
  );
};

export default TextLinkWithCarat;
