import gql from 'graphql-tag';
import LinkFragmentV3 from '../fragments/link.v3';
import ImageFragmentV3 from '../fragments/image.v3';
import {
  FooterParsedResponse,
  FooterQuery,
  FooterQueryResult
} from '../../types/Footer';
import LinkFragment from '@/graphql/types/fragment/Link';
import { OrEmptyObj } from '@/utils';

const rawQuery = gql`
  query getFooter(
    $storeID: String!
    $envEnvironmentID: String!
    $occasions: [OccasionV3!]
  ) {
    Homepage(
      envID: $envEnvironmentID
      storeID: $storeID
      occasions: $occasions
    ) {
      footer {
        menu: menuNavigation {
          sectionName
          subLinks: additionalLinks {
            ...LinkFragmentV3
          }
        }
        account: accountNavigation {
          sectionName
          createAccount: createLink {
            ...LinkFragmentV3
          }
          manageAccount: manageLink {
            ...LinkFragmentV3
          }
          signIn: signInLink {
            ...LinkFragmentV3
          }
          signOut: signOutLink {
            ...LinkFragmentV3
          }
        }
        sections: sectionsNavigation {
          sections {
            title: sectionName
            links {
              ...LinkFragmentV3
            }
          }
        }
        cta: callToAction {
          heading
          appleStoreIcon {
            ...ImageFragmentV3
          }
          appleStoreLink {
            ...LinkFragmentV3
          }
          externalLinkIcon {
            ...ImageFragmentV3
          }
          googlePlayStoreIcon {
            ...ImageFragmentV3
          }
          googlePlayStoreLink {
            ...LinkFragmentV3
          }
          fbIcon {
            ...ImageFragmentV3
          }
          fbLink {
            ...LinkFragmentV3
          }
          instagramIcon {
            ...ImageFragmentV3
          }
          instagramLink {
            ...LinkFragmentV3
          }
          twitterIcon {
            ...ImageFragmentV3
          }
          twitterLink {
            ...LinkFragmentV3
          }
          youtubeIcon {
            ...ImageFragmentV3
          }
          youtubeLink {
            ...LinkFragmentV3
          }
        }
        legal {
          links {
            newFlag
            link {
              ...LinkFragmentV3
            }
            badgeText
          }
          disclaimers {
            legalCopyright
            legalStatement
          }
        }
      }
    }
  }
  ${ImageFragmentV3}
  ${LinkFragmentV3}
`;

export interface FooterParserOptions {
  customerIsNational: boolean;
}

const parser = (
  data: FooterQueryResult
): OrEmptyObj<FooterParsedResponse> => {
  if (!data?.Homepage?.footer) {
    return {};
  }

  const {
    menu,
    account,
    sections,
    cta,
    legal
  } = data.Homepage.footer;

  const sectionsLinks = (sectionLinks: LinkFragment[]) => [...(sectionLinks || [])].filter((filtered) => filtered);

  return {
    footer: {
      cta,
      navigation: {
        menu,
        account,
        sections: {
          sections: sections.sections.map((section: { title: string; links: LinkFragment[] }) => ({
            meta: {
              title: section.title
            },
            links: {
              links: sectionsLinks(section.links)
            }
          }))
        }
      },
      legal: {
        links: {
          links: legal.links
        },
        disclaimers: legal.disclaimers
      }
    }
  };
};

const query: FooterQuery = { query: rawQuery, parser };
export default query;
