import { useDispatch } from 'react-redux';
import { AddableCartItem } from '@/api/phdApiV2Client/request.types';
import { Content, updateToast } from '@/common/CartToast/slices/Toast.slice';
import { ItemAnalytics } from '@/dataAnalytics/analyticsTypes';
import { CartItem } from '@/domain/cart/types';
import { PIZZA } from '@/domain/constants';
import { DEAL, LINEUP } from '@/deals/constants';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { Deal } from '@/builders/deals/slice/dealTypes';
import decodeEntities from '@/graphql/helpers/decodeEntities';

interface Return {
  shapeAndDisplayToast: (
    item: AddableCartItem,
    cartProcessedItem: CartItem,
    eventKeyboardClick?: boolean,
    itemAnalytics?: ItemAnalytics,
    isLineup?: boolean
  ) => void;
}

const formatPrice = (cartPrice: string, cartQuantity: number, itemQuantity: number): string => {
  if (cartQuantity === 1) return cartPrice;
  // All this arithmetic could be acounted for if backend would also pass the unit price to the cart.
  const cartPriceInt = (Math.round(((Number(cartPrice.replace(/[^0-9.-]+/g, ''))) / cartQuantity) * itemQuantity * 100) / 100).toFixed(2);
  return `$${cartPriceInt}`;
};

const useCartToast = (): Return => {
  const dispatch = useDispatch();
  const isDeal = (item: AddableCartItem): item is Deal => 'data' in item;
  const isBuiltPizza = (item: AddableCartItem): item is BuiltPizza => 'type' in item && item.type === PIZZA;

  const shapeAndDisplayToast = (
    item: AddableCartItem,
    cartProcessedItem: CartItem,
    eventKeyboardClick?: boolean,
    itemAnalytics?: ItemAnalytics,
    isLineup?: boolean
  ): void => {
    let category = '';
    if (isBuiltPizza(item)) category = PIZZA;
    if (isDeal(item)) category = DEAL;
    if (isLineup) category = LINEUP;
    let price;
    let quantity;

    if (!isDeal(item)) {
      // @ts-expect-error - existing code
      price = formatPrice(cartProcessedItem.displayablePrice, cartProcessedItem.quantity, item.quantity);
      quantity = item.quantity;
    } else {
      price = cartProcessedItem.displayablePrice;
      quantity = undefined;
    }

    const toastContent: Content = {
      isKeyboardClick: eventKeyboardClick,
      productName: decodeEntities(cartProcessedItem.name),
      productPrice: price,
      productQuantity: quantity
    };

    const analyticsObj = itemAnalytics ?? {
      item_index: 0,
      item_list: '',
      item_price: 0,
      item_name: cartProcessedItem.name,
      item_category: category,
      item_position: ''
    };
    dispatch(
      updateToast({ itemAnalytics: analyticsObj, content: toastContent })
    );
  };

  return {
    shapeAndDisplayToast
  };
};

export default useCartToast;
