import { useState, useEffect } from 'react';
import { breakPointsValues, mobileStartBreakpoint } from '../../materialUi/theme';

// Reference:
// https://usehooks.com/useWindowSize/
// https://joshwcomeau.com/react/the-perils-of-rehydration/

// eslint-disable-next-line @typescript-eslint/naming-convention
interface windowSize {
  width: number;
  height: number;
}

export default function useWindowSize(): windowSize {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        // @ts-expect-error - existing code
        width: window.innerWidth,
        // @ts-expect-error - existing code
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  // @ts-expect-error - existing code
  return windowSize;
}

// @ts-expect-error - existing code
export function isMobile(windowSize) {
  return windowSize.width && windowSize.width < breakPointsValues[mobileStartBreakpoint];
}
