import React, {
  ReactElement, useCallback, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { closeRail, selectors } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import Rail from '@/rail';
import YumSignInIframe from './signInIframe/YumSignInIframe';
import YumResetPasswordIframe from '@/account/resetPassword/YumResetPasswordIframe';
import { SIGN_IN_TITLE } from '@/rail/railContent/signInRail/constants';
import useQueryParams from '@/rail/railContent/signInRail/useQueryParams';

const SignInRail = (): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [resetCode, setResetCode] = useState<string | undefined>(router.query?.reset_code as string);
  const [railTitle, setRailTitle] = useState(SIGN_IN_TITLE);
  const visible = useSelector(selectors.isSignInRailVisible);
  const { removeQueryParam } = useQueryParams();

  const setResetCodeCallback = useCallback((newResetCode?: string) => {
    setResetCode(newResetCode);
  }, []);

  // make sure we wipe the resetCode state so signIn is used on re-open
  const handleRailClose = useCallback(() => {
    dispatch(closeRail(RailType.SIGN_IN));
    setRailTitle(SIGN_IN_TITLE);
    setResetCode(undefined);
    removeQueryParam('reset_code');
  }, [dispatch, removeQueryParam]);

  const setRailTitleCallback = useCallback((newRailTitle: string) => {
    setRailTitle(newRailTitle);
  }, [setRailTitle]);

  const getComponent = () => (
    !resetCode ? <YumSignInIframe setRailTitle={setRailTitleCallback} title={railTitle} /> : <YumResetPasswordIframe setRailTitle={setRailTitleCallback} title={railTitle} resetCode={resetCode} setResetCode={setResetCodeCallback} />
  );
  return (
    <Rail
      title={railTitle}
      visible={visible}
      onClose={handleRailClose}
    >
      { getComponent() }
    </Rail>
  );
};

export default SignInRail;
