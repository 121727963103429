import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { joinText } from '../../common/string-formatter';
import selectDefaults from '../../builders/pizza/slice/selectDefaults';
import { Pizza, PizzaOptions } from '@/builders/pizza/dataTransformers/builderTypes';

const formatPizzaAnalytics = (pizza: Pizza) => ({
  id: pizza.id,
  name: pizza.name,
  // @ts-expect-error - existing code
  price: pizza.price / 100,
  sauce: pizza.sauce?.name,
  cheese: pizza.cheese?.name,
  crust: pizza.crust?.name,
  finisher: pizza.finisher?.name,
  // @ts-expect-error - existing code
  veggies: joinText(pizza.veggieToppings.map(({ name }) => name)),
    // @ts-expect-error - existing code
  meats: joinText(pizza.meatToppings.map(({ name }) => name))
});

const pizzaAnalyticsSlice = createSlice({
  name: 'pizza',
  initialState: {},
  reducers: {
    updatePizzaOptions: (state, action: PayloadAction<PizzaOptions>) => {
      const pizza = selectDefaults(action.payload);
      const pizzaAnalytics = formatPizzaAnalytics(pizza);
      Object.assign(state, pizzaAnalytics);
    }
  }
});

export const {
  updatePizzaOptions
} = pizzaAnalyticsSlice.actions;

export default pizzaAnalyticsSlice.reducer;
