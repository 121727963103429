import gql from 'graphql-tag';
import LinkFragmentV3 from '../fragments/link.v3';
import ImageFragmentV3 from '../fragments/image.v3';
import {
  HutFavoritesQuery,
  HutFavoritesQueryResultV3
} from '../../types/HutFavorites';
import { HutFavorites } from '../../../homepage/HomepageTypes';
import { sortByIndex } from '../categories';

const rawQuery = gql`
  query getHutFavorites($envEnvironmentID: String!) {
    Homepage(envID: $envEnvironmentID) {
      hutFavorites {
          ctaText
          image {
              ...ImageFragmentV3
          }
          index
          link {
              ...LinkFragmentV3
          }
          name
          parent
          subtitle
          tagLine
          title
      }
      hutFavoritesHeading {
          cta {
              ...LinkFragmentV3
          }
          displayName
          heading
          name
          parent
      }
    }
  }
  ${ImageFragmentV3}
  ${LinkFragmentV3}
`;

const parser = (
  data: HutFavoritesQueryResultV3
) : HutFavorites | null => {
  if (!data) return null;

  const hutFavorites = [...data?.Homepage?.hutFavorites];
  const transformedFavorites = hutFavorites.sort(sortByIndex)
    .map((fav) => ({
      favoriteID: fav.index,
      title: fav.title,
      subTitle: fav.subtitle,
      tagLine: fav.tagLine,
      cta: fav.ctaText,
      image: fav.image,
      link: fav.link
    }));

  return {
    Env: {
      hutfavorites: {
        meta: {
          heading: data?.Homepage?.hutFavoritesHeading?.heading,
          ctaLink: data?.Homepage?.hutFavoritesHeading?.cta
        },
        products: {
          favorites: transformedFavorites
        }
      }
    }
  };
};

// @ts-expect-error: Expect type error for parser function
const query: HutFavoritesQuery = { query: rawQuery, parser };
export default query;
