import { CartInformation } from '../../cart/legacyCart/legacyCartType';

enum AlcoholItemClass {
  BEER = 'BR',
  WINE = 'W'
}

const checkAlcoholInCart = (orderItems: []) => {
  const alcoholItemClass = Object.values(AlcoholItemClass);
  const alcoholItem = ({ ga_class: gaClass }: { ga_class: AlcoholItemClass}) => alcoholItemClass.includes(gaClass);

  return Boolean(orderItems && orderItems.find(alcoholItem));
};

export default function transformCartInfo(
  // eslint-disable-next-line camelcase
  cartInfo?: { summary_info?: any; order_items?: any }
) : CartInformation {
  let quantity = 0;
  let subtotal = '$0.00';
  let hasAlcoholInCart = false;
  if (cartInfo) {
    const { summary_info: summaryInfo, order_items: orderItems } = cartInfo;
    if (summaryInfo) {
      quantity = summaryInfo.quantity;
      subtotal = summaryInfo.subtotal;
    }

    if (orderItems) {
      hasAlcoholInCart = checkAlcoholInCart(orderItems);
    }
  }
  return {
    quantity, subtotal, hasAlcoholInCart, error: false
  };
}
