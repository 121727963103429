import isClientSide from '../../common/isClientSide';

// The library we're using doesn't provide methods to get or set
// Using methods here lets us mock the values with jest
let get: () => string | undefined;
let set: (conversationID: string) => void;
if (isClientSide()) {
  get = () => undefined;
  set = () => undefined;
} else {
  // eslint-disable-next-line global-require
  const cls = require('simple-continuation-local-storage');

  get = () => cls.convID;
  set = (value: string) => {
    cls.$init();

    cls.convID = value;
  };
}

const ClsCrudHelper = { get, set };
export default ClsCrudHelper;
