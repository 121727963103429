import React, { useEffect, useState } from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import SidekickMedia from './Media';
import SidekickContent from './Content';
import useStyles from './styles';
import DealLink from '../../../common/DealLink';
import { DefaultImage, LARGE_IMAGE_GRID_SIZE, SMALL_IMAGE_GRID_SIZE } from '../constants';
import { logMissingImageError, logMissingLinkError } from '../../../common/logger/logGraphqlError';
import useScrollTrackingForAnalytics from '../../../dataAnalytics/hooks/useScrollTrackingForAnalytics';
import {
  createPromo,
  onInitialHomepagePageLoadPromoInView,
  onPromoClick
} from '../../../dataAnalytics/dataAnalyticsHelper';
import telemetry from '../../../telemetry';
import { Device, HiddenOnDevice } from '../../../common/ResponsiveContext';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { AnalyticsDataModel } from '@/rootStateTypes';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';
import isClientSide from '@/common/isClientSide';

const { DESKTOP_SMALL_IMAGE, DESKTOP_LARGE_IMAGE, MOBILE_IMAGE } = DefaultImage;

interface SidekickProps {
  link: LinkContent;
  index: number;
  imageLarge: Image;
  imageSmall: Image;
  subTitle: string;
  title?: string;
  isSmallImage: boolean;
  badgeText?: string;
  loading: boolean;
  storeID: string | null;
}

interface ImageOrFallback extends Image {
  desktop: string;
  mobile: string;
}

const SideKick = (
  {
    link,
    index,
    imageLarge,
    imageSmall,
    isSmallImage,
    subTitle,
    title,
    badgeText,
    loading,
    storeID
  }: SidekickProps
) => {
  const classes = useStyles();
  const sidekickContentProps = {
    title,
    subTitle,
    badgeText,
    loading
  };

  const [hasLoggedSidekickError, setHasLoggedSidekickError] = useState(false);
  const [hasLoggedMissingLinkError, setHasLoggedMissingLinkError] = useState(false);

  const [preparedMobileImage, prepareMobileImage] = useState(getValidImageOrFallback(imageSmall));
  const [preparedDesktopImage, prepareDesktopImage] = useState(getValidImageOrFallback(imageLarge));
  const optimizelyInstance = getOrInitializeOptimizely({ datafile: {}, isBrowser: isClientSide(), allowInitialize: false });

  useEffect(() => {
    prepareMobileImage(getValidImageOrFallback(imageSmall));
    prepareDesktopImage(getValidImageOrFallback(imageLarge));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLarge, imageSmall]);

  const analytics = useAnalytics();
  const loadingSidekicks = analytics.analyticsDataModel.page?.promo?.sidekicks;

  const promo = (dataModel: AnalyticsDataModel) => createPromo(dataModel?.page?.promo?.sidekicks[index]);
  const label = `Sidekick ${index + 1}`;

  const passPromoOnClickAnalytics = () => {
    if (!loadingSidekicks) return;
    telemetry.addCustomEvent(`homepage-sidekick-${index + 1}-click`);
    analytics.push(() => onPromoClick(label, promo(analytics.analyticsDataModel)));
    if (storeID) {
      const tileOrder = index + 1;
      optimizelyInstance?.track(`SK${tileOrder}Click`);
    }
  };

  function getValidImageOrFallback(image: Image): ImageOrFallback {
    if (loading) {
      return {
        title: '',
        altText: '',
        mobile: '',
        desktop: ''
      };
    }

    if (!image || !image.mobile || !image.desktop) {
      if (!hasLoggedSidekickError) {
        logMissingImageError([{ image }], 'Homepage sidekick', storeID);
        setHasLoggedSidekickError(true);
      }

      return {
        ...image,
        desktop: isSmallImage ? DefaultImage.DESKTOP_SMALL_IMAGE : DefaultImage.DESKTOP_LARGE_IMAGE,
        mobile: DefaultImage.MOBILE_IMAGE
      };
    }

    return image;
  }

  const desktopImage = {
    title: isSmallImage ? preparedMobileImage.title : preparedDesktopImage.title,
    alt: isSmallImage ? preparedMobileImage.altText : preparedDesktopImage.altText,
    image: isSmallImage ? preparedMobileImage.desktop : preparedDesktopImage.desktop,
    fallbackImage: isSmallImage ? DESKTOP_SMALL_IMAGE : DESKTOP_LARGE_IMAGE
  };

  const mobileImage = {
    title: preparedMobileImage.title,
    alt: preparedMobileImage.altText,
    image: preparedMobileImage.mobile,
    fallbackImage: MOBILE_IMAGE
  };

  const deviceGridSize: GridSize = isSmallImage ? SMALL_IMAGE_GRID_SIZE : LARGE_IMAGE_GRID_SIZE;

  useEffect(() => {
    if (loading) return;

    if ((!link || !link.link) && !hasLoggedMissingLinkError) {
      logMissingLinkError([{ link }], 'Homepage sidekick', storeID);
      setHasLoggedMissingLinkError(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const ref = useScrollTrackingForAnalytics(
    onInitialHomepagePageLoadPromoInView,
    label,
    promo,
    0.75,
    !loadingSidekicks
  );

  return (
    <Grid
      item
      xs={12}
      md={deviceGridSize}
      onClick={passPromoOnClickAnalytics}
    >
      <DealLink
        {...link}
        testId={`homepage-sidekick_${index}-link`}
        className={classes.anchorLink}
        dataAnalyticsCategory="sidekicks"
        dataAnalyticsAction={`sidekick_${index}`}
        linkRef={ref}
        tabIndex={0}
      >
        <Card
          className={classes.card}
          data-testid={`homepage-sidekick_${index}-image`}
        >
          <HiddenOnDevice implementation="css" {...Device.DESKTOP}>
            <Grid container alignItems="center" data-testid="mobile-sidekick">
              <Grid item xs={3} className={classes.grid}>
                <SidekickMedia
                  index={index}
                  loading={loading}
                  classes={classes}
                  desktopImage={desktopImage.image}
                  mobileImage={mobileImage.image}
                  {...mobileImage}
                />
              </Grid>
              <Grid item xs={9}>
                <SidekickContent {...sidekickContentProps} />
              </Grid>
            </Grid>
          </HiddenOnDevice>
          <HiddenOnDevice implementation="css" {...Device.MOBILE}>
            <div data-testid="desktop-sidekick">
              <SidekickMedia
                index={index}
                loading={loading}
                classes={classes}
                desktopImage={desktopImage.image}
                mobileImage={mobileImage.image}
                isDesktopSmallImage={isSmallImage}
                {...desktopImage}
              />
              <SidekickContent {...sidekickContentProps} />
            </div>
          </HiddenOnDevice>
        </Card>
      </DealLink>
    </Grid>
  );
};

export default SideKick;
