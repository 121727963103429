import logger from '../../common/logger';
import StandaloneEndpoints from './endpoints';
import loggingFetch from '../../common/loggingFetch';
import telemetry from '../../telemetry';

interface RequestBody {
  [key: string]: any;
}

type QueryParams = { [key: string]: string | number };

const getEndpointWithParams = (endpoint: StandaloneEndpoints, params?: QueryParams) => {
  if (!params) {
    return endpoint;
  }

  const queryStr = new URLSearchParams(
    Object.entries(params).map(([key, value]) => [key, String(value)])
  ).toString();
  return `${endpoint}?${queryStr}`;
}

const logError = (endpoint: StandaloneEndpoints, error: Error) => {
  telemetry.addNoticeError(error, { endpoint });
  logger.withoutTelemetry.error(error.message);
};

const prependTestBaseUrl = (path: string) => {
  if (process.env.NODE_ENV !== 'test') {
    return path;
  }
  return `https://local.pizzahut.com${path}`;
};

const post = (
  body: RequestBody, endpoint: StandaloneEndpoints
): Promise<any> => loggingFetch(prependTestBaseUrl(endpoint), {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body),
  redirect: 'follow'
})
  .then((response) => response.json())
  .catch((error) => {
    logError(endpoint, error);
    // Return an empty object if we error, to prevent errors w/ destructuring
    return {};
  });

const get = (
  endpoint: StandaloneEndpoints,
  params?: QueryParams
): Promise<any> => loggingFetch(prependTestBaseUrl(getEndpointWithParams(endpoint, params)), {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  },
  redirect: 'follow'
})
  .then((response) => response.json())
  .catch((error) => logError(endpoint, error));

const remove = (
  endpoint: StandaloneEndpoints,
  params?: QueryParams
): Promise<any> => loggingFetch(prependTestBaseUrl(getEndpointWithParams(endpoint, params)), {
  method: 'DELETE',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  },
  redirect: 'follow'
})
  .then((response) => response.json())
  .catch((error) => logError(endpoint, error));

export default {
  post,
  get,
  remove
};
