import gql from 'graphql-tag';
import LinkFragmentV3 from '../fragments/link.v3';
import { BreakingNews, BreakingNewsQuery, BreakingNewsResult } from '../../types/BreakingNews';

const rawQuery = gql`
  query getBreakingNews($envEnvironmentID: String!) {
    Homepage(envID: $envEnvironmentID) {
        breakingnews: breakingNews {
        active
        link {
          ...LinkFragmentV3
        }
        text
      }
    }
  }
  ${LinkFragmentV3}
`;

const parser = (data: BreakingNewsResult) : BreakingNews => ({
  // @ts-expect-error - existing code
  breakingnews: data?.Homepage?.breakingnews
});

// @ts-expect-error Expect error for the parser function
const query: BreakingNewsQuery = { query: rawQuery, parser };
export default query;
