import { checkResponseStatus, post } from '@/api/clientHelpers';
import { PhPayGiftCardResponse, PhPayLegacyGiftCardBody } from '../../phPayApiClient/types';
import { GiftCardBalance } from '@/checkout/types';
import PhdApiEndpoints from '../endpoints';
import { getUrl } from '../helpers/getUrl';

const transformGiftCardResponse = (response: PhPayGiftCardResponse): GiftCardBalance => ({
  balance: response?.balance || 0
});

export const getBalance = async (
  body: PhPayLegacyGiftCardBody,
  mockFunction: () => Promise<Response>
): Promise<ClientResult<GiftCardBalance>> => {
  const phPayUrl = getUrl(PhdApiEndpoints.LEGACY_GIFT_CARD_BALANCE);

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Auth-Channel': 'WEB2'
  };

  return post(phPayUrl, headers, body, mockFunction)
    .then(checkResponseStatus)
    .then((response) => response.json())
    .then(transformGiftCardResponse)
    .then((result) => ({
      error: false,
      result
    }))
    .catch(() => ({
      error: true
    }));
};
