import query from './query';
import { DealQuery } from '../../types/Deal';
import { GqlVersion } from '@/graphql/hooks/useGqlVersion/GqlVersion';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useQuery(version?: GqlVersion): DealQuery {
  return query;
}

export default useQuery;
