import getConfig from 'next/config';

type PublicRuntimeConfig = {
  phdApiUrl: string;
  phdApiKey: string;
  phdApiAuthChannel: string;
};

export const getPublicRuntimeConfig = (): PublicRuntimeConfig => {
  const { publicRuntimeConfig } = getConfig();

  return {
    phdApiUrl: publicRuntimeConfig.PHD_API_URL,
    phdApiKey: publicRuntimeConfig.PHD_API_KEY,
    phdApiAuthChannel: publicRuntimeConfig.PHD_API_AUTH_CHANNEL
  };
};
