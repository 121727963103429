import loggingFetch from '@/common/loggingFetch';

type FetchOptionsBuilder = (options: Partial<RequestInit>) => Partial<RequestInit>;

const defaultFetchOptionsBuilder:FetchOptionsBuilder = (options) => options;
const fetch = (
  method: string,
  url: string,
  fetchOptionsBuilder = defaultFetchOptionsBuilder,
  body?: BodyInit
) => loggingFetch(url, {
  method,
  ...(fetchOptionsBuilder({
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body
  }))
});

const editFetch = (
  url: string, fetchOptionsBuilder?: FetchOptionsBuilder, body?: BodyInit
): Promise<Response> => fetch('PUT', url, fetchOptionsBuilder, body);
const getFetch = (
  url: string, fetchOptionsBuilder?: FetchOptionsBuilder
): Promise<Response> => fetch('GET', url, fetchOptionsBuilder);
const deleteFetch = (
  url: string, fetchOptionsBuilder?: FetchOptionsBuilder
): Promise<Response> => fetch('DELETE', url, fetchOptionsBuilder);
const postFetch = (
  url: string, fetchOptionsBuilder?: FetchOptionsBuilder, body?: BodyInit
): Promise<Response> => fetch('POST', url, fetchOptionsBuilder, body);
const putFetch = (
  url: string, fetchOptionsBuilder?: FetchOptionsBuilder, body?: BodyInit
): Promise<Response> => fetch('PUT', url, fetchOptionsBuilder, body);
const includeCredentials: FetchOptionsBuilder = (options) => ({
  ...options,
  credentials: 'include'
});

export type {
  FetchOptionsBuilder
};

export {
  editFetch as phdapiEdit,
  getFetch as phdapiGet,
  deleteFetch as phdapiDelete,
  postFetch as phdapiPost,
  putFetch as phdapiPut,
  includeCredentials
};
