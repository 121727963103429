import { checkResponseStatus, get } from '@/api/clientHelpers';
import PhdApiEndpoints from '../endpoints';
import { getUrl } from '../helpers/getUrl';
import {
  DeliveryOrderStatusResponse,
  CarryoutOrderStatusResponse,
  GetOrderStatus
} from '@/api/phdApiClient/types';

const transformDeliveryOrderStatus = (
  response: GetOrderStatus
): DeliveryOrderStatusResponse => ({
  estimatedDeliveryTime: response.estimatedDeliveryTime,
  steps: response.steps,
  storeNumber: response.storeNumber
});

const getOrderStatusEndpoint = (
  endpoint: string,
  orderId: string
) => endpoint.replace('%s', orderId);

export const getDeliveryOrderStatus = (
  orderId: string,
  mockFunction: () => Promise<Response>
): Promise<ClientResult<DeliveryOrderStatusResponse>> => {
  const orderStatusEndpoint = getOrderStatusEndpoint(
    getUrl(PhdApiEndpoints.DELIVERY_ORDER_STATUS),
    orderId
  );

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json'
  };

  return get(orderStatusEndpoint, headers, mockFunction)
    .then(checkResponseStatus)
    .then((response) => response.json())
    .then(transformDeliveryOrderStatus)
    .then((result) => ({
      error: false,
      result
    }))
    .catch(() => ({ error: true }));
};

export const getCarryoutOrderStatus = (
  orderId: string,
  mockFunction: () => Promise<Response>
): Promise<ClientResult<CarryoutOrderStatusResponse>> => {
  const orderStatusEndpoint = getOrderStatusEndpoint(
    getUrl(PhdApiEndpoints.CARRYOUT_ORDER_STATUS),
    orderId
  );

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json'
  };

  return get(orderStatusEndpoint, headers, mockFunction)
    .then(checkResponseStatus)
    .then((response) => response.json())
    .then((result) => ({
      error: false,
      result
    }))
    .catch(() => ({ error: true }));
};
