import gql from 'graphql-tag';
import { CategoryDisplayNameQuery } from '../../types/Category';
import transformer from './transformer';

const GET_CATEGORY_DISPLAY_NAME = gql`
  query getCategoryDisplayName($categoryID: String!, $envID: String!) {
    Homepage(envID: $envID) {
      category(categoryID: $categoryID) {
        displayName
        heading
        subHeading
      }
    }
  }
`;

const query = (): CategoryDisplayNameQuery => (
  // @ts-expect-error Expect error for the parser function
  { query: GET_CATEGORY_DISPLAY_NAME, parser: transformer }
);

export default query;
