import { combineReducers } from 'redux';
import { presentationalRailReducer } from '@/clientCore/redux/rail/CartRailSlice';
import { presentationalCartStateReducer } from './cart/CartSlice';
import { confirmationStateReducer } from './confirmation/ConfirmationSlice';
import { checkoutStateReducer } from '@/clientCore/redux/checkout/CheckoutSlice';
import { debugModeStateReducer } from './debug/DebugSlice';

const rootReducer = {
  clientCorePresentational: combineReducers({
    rail: presentationalRailReducer,
    cart: presentationalCartStateReducer
  }),
  confirmation: confirmationStateReducer,
  checkout: checkoutStateReducer,
  debugMode: debugModeStateReducer
};

export default rootReducer;
