import React, { ReactElement, useState } from 'react';

const YumLogoutHandler = (): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logoutUrl, setLogoutUrl] = useState<URL | null>(null);

  return logoutUrl ? (
    <iframe
      data-testid="yum-logout-iframe"
      src={String(logoutUrl)}
      height={0}
      width={0}
      title="Yum Logout"
    />
  ) : <></>;
};

export default YumLogoutHandler;
