import { Action, combineReducers } from 'redux';
import { UPDATE_PROMO_DATA } from '../../ReduxApplication/actionTypes';
import isSmallSidekickImage from '../../common/isSmallSidekickImage';
import { MAX_SIDEKICK_IMAGES } from '../../homepage/sidekicks/constants';
import { PromoType } from '../dataAnalytics.d';

const createPromoData = (
  promoCreative: string,
  promoType: string,
  promoPosition: string,
  promoName?: string,
  promoID?: string
) => ({
  promoCreative,
  promoType,
  promoPosition,
  promoName,
  promoID
});

const createItemData = (
    itemList: string,
    itemIndex: number,
    itemPrice: number,
    itemName: string,
    itemCategory: string,
    itemPosition: string,
    itemID: string
) => ({
  itemList,
  itemIndex,
  itemPrice,
  itemName,
  itemCategory,
  itemPosition,
  itemID
});

const getSidekickPromoData = (sidekicks: any[], isMobile: any) => {
  const hasMoreThanMaxNumberOfSidekicks = sidekicks?.length > MAX_SIDEKICK_IMAGES;
  const numberOfSidekicks = hasMoreThanMaxNumberOfSidekicks
    ? MAX_SIDEKICK_IMAGES
    : sidekicks?.length;

  return sidekicks?.map((sidekick, index) => {
    const isSmallImage = isSmallSidekickImage(index, numberOfSidekicks);
    const promoCreative = isSmallImage ? sidekick.imageSmall : sidekick.imageLarge;

    return createPromoData(
      isMobile ? promoCreative?.mobile : promoCreative?.desktop,
      'Sidekick',
      `Sidekick Image Slide ${index + 1}`,
      sidekick.title,
      sidekick.sidekickID
    );
  });
};

const getHeroPromoData = (
  heroes: any[], isMobile: any
) => heroes
  ?.slice(0, 1)
  .map((hero, index) => createPromoData(
    isMobile ? hero.image.mobile : hero.image.desktop,
    'hero',
    `Hero Image Slide ${index + 1}`,
    hero?.image?.title,
    hero?.heroID
  ));

const getBreakingNewsPromoData = (
  breakingnews: any
) => (breakingnews?.active
    ? createPromoData(
      breakingnews.text,
      'Breaking News',
      'Breaking News',
      'Breaking News'
    )
    : undefined
);

const getMarketingBannerPromoData = (
  marketingBanners: MarketingBannerContent[], isMobile: boolean
) => marketingBanners
  ?.slice(0, 2)
  .map((banner, index) => createPromoData(
    isMobile ? banner.image.mobile : banner.image.desktop,
    'Marketing Banner',
    `Marketing Banner ${index + 1}`,
    banner?.image?.title,
    banner?.bannerID
  ));

const promo = (state: any = {}, action: Action) => {
  switch (action.type) {
    case UPDATE_PROMO_DATA: {
      const { promoType, promoData, isMobile } = (action as any);

      switch (promoType) {
        case PromoType.HEROES_PROMO: {
          return {
            ...state,
            heroes: getHeroPromoData(promoData, isMobile) || []
          };
        }
        case PromoType.SIDEKICKS_PROMO: {
          return {
            ...state,
            sidekicks: getSidekickPromoData(promoData, isMobile) || []
          };
        }
        case PromoType.BREAKING_NEWS_PROMO: {
          return {
            ...state,
            breakingNews: getBreakingNewsPromoData(promoData) || undefined
          };
        }
        case PromoType.MARKETING_BANNERS_PROMO: {
          return {
            ...state,
            marketingBanners: getMarketingBannerPromoData(promoData, isMobile) || []
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

const getHutFavoritesItemData = (hutfavorites: HutFavoritesContent) => hutfavorites?.products?.favorites.slice(0, 4).map(
    (favorite, index) => {
      const indexOfPrice = favorite.tagLine.indexOf('$') + 1;
      const itemPrice = indexOfPrice > 0
        ? Number(favorite.tagLine.substring(indexOfPrice))
        : undefined;

      const itemID = favorite.favoriteID;

      return createItemData(
        'Home',
        index,
        // @ts-expect-error - existing code
        itemPrice,
        favorite.title,
        'pizza',
        `Popular Pizza ${index + 1}`,
        itemID
      )
    });
  
const items = (state: any = [], action: Action) => {
  switch (action.type) {
    case UPDATE_PROMO_DATA: {
      const { promoType, promoData } = (action as any);
      switch (promoType) {
        case PromoType.HUT_FAVORITES_PROMO: {
          return getHutFavoritesItemData(promoData) || []
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default combineReducers({
  promo,
  items
});
