import React, { createContext, useCallback, useState } from 'react';
import {
  CCStoreResponse, StoreSearchPayload, useStoreSearchMutation, DeliveryAddress, DiningOccasion,
  storeApiInfo
} from '@pizza-hut-us-development/client-core';
import { useDispatch } from 'react-redux';

import { useDecision } from '@optimizely/react-sdk';
import {
  carryoutOptionsFromPayload,
  deliveryAddressFromPayload,
  showCarryoutNoStoresFoundModal,
  showDeliveryNoStoresFoundModal
} from '@/clientCore/localization/helpers';
import telemetry from '@/telemetry';
import { storeSearchNoResultsAnalytics, storeSearchSuccessAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';
import { Analytics } from '@/dataAnalytics/hooks/useAnalytics';
import { OccasionStringValues } from '@/localization/constants';

export type LocalizationRailContextType = {
  showSearch: boolean;
  results?: CCStoreResponse[];
  deliveryAddress?: DeliveryAddress;
  isLoading?: boolean;
  setShowSearch: (value: boolean) => void;
  setGpsLoading: (value: boolean) => void;
  handleStoreSearch: (payload: StoreSearchPayload, analytics: Analytics, isSavedAddress?: boolean) => void;
};

export const LocalizationRailContext = createContext<LocalizationRailContextType | null>(null);

const LocalizationRailProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();

  const [showSearch, setShowSearch] = useState(true);
  const [storeSearch, storeSearchResult] = useStoreSearchMutation();
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>();
  const [gpsLoading, setGpsLoading] = useState(false);
  const [searchedStoreNumber, setSearchedStoreNumber] = useState('');
  const [{enabled: localizationAnalyticsEnabled }] = useDecision('fr-web-4280-localization-analytics')

  const results = searchedStoreNumber
    ? storeSearchResult.data?.filter((store) => store.storeNumber === searchedStoreNumber)
    : storeSearchResult.data;
  const isLoading = storeSearchResult?.isLoading || gpsLoading;

  const searchSpecificStore = useCallback(async (storeNumber: string) => {
    const result = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(storeNumber)) as unknown as { data: CCStoreResponse };
    return {
      city: result.data.city,
      state: result.data.state,
      postalCode: result.data.postalCode
    };
  }, [dispatch]);

  const sendNoStoresFoundAnalytics = (analytics: Analytics ,occasion: OccasionStringValues, payload: StoreSearchPayload, isSavedAddress: boolean) => {
    analytics?.push(() => storeSearchNoResultsAnalytics(
      occasion,
      {
        city: payload.city,
        state: payload.state,
        zipcode: payload.postalCode
      },
      isSavedAddress
    ));
  }

  const handleStoreSearch = useCallback(async (payload: StoreSearchPayload, analytics: Analytics, isSavedAddress = false) => {
    let modifiedPayload = payload;

    // Support searching for a specific store when passing store-{store_number} as the city
    let storeNumber = '';
    if (payload.city?.includes('store-')) {
      storeNumber = payload.city.substring(payload.city.indexOf('-') + 1);
      const store = await searchSpecificStore(storeNumber);
      modifiedPayload = {
        ...payload,
        ...store
      };
    }
    setSearchedStoreNumber(storeNumber);

    const storeResults = await storeSearch(modifiedPayload);

    if (!('data' in storeResults)) {
      switch (payload.occasionId) {
        case DiningOccasion.DELIVERY:
          if (localizationAnalyticsEnabled){
            sendNoStoresFoundAnalytics(analytics, 'Delivery', payload, isSavedAddress);
          }
          showDeliveryNoStoresFoundModal(dispatch, deliveryAddressFromPayload(payload), analytics, isSavedAddress);
          break;
        case DiningOccasion.CARRYOUT:
          if (localizationAnalyticsEnabled){
            sendNoStoresFoundAnalytics(analytics, 'Carryout', payload, isSavedAddress);
          }
          showCarryoutNoStoresFoundModal(dispatch, carryoutOptionsFromPayload(payload), analytics, isSavedAddress);
          break;
        default: break;
      }
      return;
    }

    if (payload.occasionId === DiningOccasion.DELIVERY) {
      setDeliveryAddress(deliveryAddressFromPayload(payload, storeResults?.data?.[0]));
      telemetry.addCustomEvent('web2-search-delivery-stores');
    } else {
      setDeliveryAddress(undefined);
      telemetry.addCustomEvent('web2-search-carryout-stores');
    }

    if(localizationAnalyticsEnabled) {
      analytics?.push(() => storeSearchSuccessAnalytics(payload.occasionId, isSavedAddress));
    }

    setShowSearch(false);
  }, [dispatch, searchSpecificStore, storeSearch, localizationAnalyticsEnabled]);

  return (
    <LocalizationRailContext.Provider value={{
      showSearch,
      results,
      deliveryAddress,
      isLoading,

      setShowSearch,
      setGpsLoading,
      handleStoreSearch
    }}
    >
      {children}
    </LocalizationRailContext.Provider>
  );
};

export default LocalizationRailProvider;
