import { NextRouter } from 'next/router';

enum Paths {
  HOME = '/',
  PIZZA_BUILDER = '/menu/pizza/builder'
}

const analyticsPageMapper: { [key: string]: string } = {
  [Paths.HOME]: '/home',
  [Paths.PIZZA_BUILDER]: '/builder/pizza builder'
};

const useMappedRoute = (
  nextRouter: NextRouter
): string => (process.env.REACT_APP_EMBEDDED === 'true' ? 'web1' : analyticsPageMapper[nextRouter.pathname] || nextRouter.pathname);

export default useMappedRoute;
