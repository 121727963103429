import {
  flatMap, groupBy, map, pipe
} from 'remeda';
import Portion from '../../../common/Portion';
import {
  extractGlobalId,
  isCheese,
  isFinisher,
  isMeat,
  isSauce,
  isSize,
  isVeggie
} from '../identifiers';
import {
  Modifiers,
  Modifiers2,
  Modifiers3,
  PizzaBuilder
} from '../../../graphql/types/PizzaBuilder';
import NutritionFragment from '../../../graphql/types/fragment/Nutrition';
import {
  PizzaIngredientOption,
  IngredientOptionWithPortions,
  RuleOption,
  RuleItem,
  CrustOption,
  PizzaOptions,
  PizzaSummaryOption
} from './builderTypes';
import { IsCyo } from '@/menu/pizza/pizzaMenuTypes';
import { IngredientInternalName } from '../identifiers/constants';
import ProductId from '@/common/ProductId';
import decodeEntities from '@/graphql/helpers/decodeEntities';
import ItemFragment from '@/graphql/types/fragment/Item';

const transformNutritionPortions = (nutrition: Partial<NutritionFragment>[]) => nutrition?.map(({ portion, ...res }) => ({
  ...res,
  portion: portion && portion.toLowerCase()
}));

export const transformPortions = (modifiers: Modifiers3[]): PizzaIngredientOption[] => modifiers
  .map(({ imageURL: image = '', portion, ...sauceData }) => ({
    ...sauceData,
    portion: Portion[portion?.toUpperCase() as keyof typeof Portion],
    image
  }));

const transformCheeseOptions = (
  itemData: Modifiers[] | undefined
): IngredientOptionWithPortions[] | undefined => itemData
  ?.filter(isCheese)
  .flatMap((x: Modifiers) => x.modifiers)
  .map(
    ({
      modifiers,
      portion,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      nutrition,
      imageURL: image = '',
      ...groupModifier
    }) => ({
      ...groupModifier,
      image,
      portions: transformPortions(modifiers),
      portion: Portion[portion as keyof typeof Portion],
      nutrition: null,
      name: decodeEntities(groupModifier.name)
    })
  );

const getModifierId = (
  modifier: Modifiers | Modifiers2 | Modifiers3
): string[] => {
  const id = new ProductId(modifier.id).globalId;
  if ('modifiers' in modifier && modifier.modifiers.length > 0) {
    const arrayOfIds = modifier.modifiers.flatMap((mod) => getModifierId(mod));
    return [...arrayOfIds];
  }
  return [id];
};

export const getInternalNameByModifier = (
  modifiers: Modifiers[] | undefined
): Record<string, string[]> => {
  const modifiersObj: Record<string, string[]> = {
    [IngredientInternalName.SIZES]: [],
    [IngredientInternalName.MEATS]: [],
    [IngredientInternalName.VEGGIES]: [],
    [IngredientInternalName.SAUCES]: [],
    [IngredientInternalName.CHEESES]: [],
    [IngredientInternalName.FINISHERS]: []
  };
  if (modifiers && modifiers.length > 0) {
    modifiers.forEach((modifier) => {
      modifiersObj[modifier.internalName] = [
        ...getModifierId(modifier).flatMap((mod) => mod),
        new ProductId(modifier.id).globalId
      ];
    });
  }

  return modifiersObj;
};

const transformRuleOptions = (
  sizes: Modifiers2[] = [],
  modifiers: Modifiers[] | undefined
): Partial<RuleOption> => {
  const internalNamesById = getInternalNameByModifier(modifiers);

  const getInternalName = (modifierId: string): string | undefined => {
    let internalName: string | undefined;
    Object.entries(internalNamesById).find(([internalNameGroup, ids]) => {
      const id = new ProductId(modifierId).globalId;
      if (ids.includes(id)) {
        internalName = internalNameGroup;
        return true;
      }
      return false;
    });
    return internalName;
  };

  const ruleItem = (crust: Modifiers3 | null, size: Modifiers2 | null) => {
    const sizeId = extractGlobalId(size);
    const crustId = extractGlobalId(crust);

    return ({ actionItem, action: rule }: Rule): RuleItem => ({
      ...actionItem,
      rule,
      crustId,
      sizeId,
      internalName: getInternalName(actionItem.id)
    });
  };

  return pipe(
    sizes,
    flatMap((size) => flatMap(size.modifiers, (crust) => map(crust.rules, ruleItem(crust, size)))),
    groupBy((item) => item.rule)
  );
};

const transformPizzaOptions = (
  itemData: Modifiers[] | undefined,
  filterFn: (item: Modifiers) => boolean,
  filterNutritionByPortion = false
): PizzaIngredientOption[] | undefined => itemData
  ?.filter(filterFn)
  .flatMap((x: Modifiers) => x.modifiers)
  .map(
    ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      modifiers,
      portion,
      nutrition,
      imageURL: image = '',
      ...groupModifier
    }) => ({
      ...groupModifier,
      image,
      portions: false,
      portion: Portion[portion as keyof typeof Portion],
      name: decodeEntities(groupModifier.name),
      nutrition: transformNutritionPortions(nutrition)?.filter(
        // TODO: Remove after gql data is fixed.
        (item: Nutrition) => !filterNutritionByPortion || item.portion === Portion.REGULAR
      )
    })
  );

const getSizeOptions = (
  itemData: Modifiers[] | undefined
): Modifiers2[] | undefined => itemData?.filter(isSize).flatMap((x: Modifiers) => x.modifiers);

const transformSizeOptions = (
  sizes: Modifiers2[] | undefined
): PizzaIngredientOption[] | undefined => sizes?.map(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    modifiers, portion, imageURL: image = '', ...groupModifier
  }) => ({
    ...groupModifier,
    portion: Portion[portion as keyof typeof Portion],
    image,
    name: decodeEntities(groupModifier.name)
  })
);

const transformCrustOptions = (
  sizes: Modifiers2[] | undefined
): CrustOption[] | undefined => sizes?.flatMap(({ id: sizeId, modifiers: crusts }) => crusts.flatMap(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageURL: image = '', portion, rules, ...crustData
  }) => ({
    ...crustData,
    portion: Portion[portion as keyof typeof Portion],
    sizeId,
    image,
    name: decodeEntities(crustData.name)
  })
));

const transformSauceOptions = (
  itemData: Modifiers[] | undefined
): IngredientOptionWithPortions[] | undefined => itemData
  ?.filter(isSauce)
  .flatMap((x: Modifiers) => x.modifiers)
  .map(({
    modifiers, portion, imageURL: image = '', ...groupModifier
  }) => ({
    ...groupModifier,
    image,
    portion: Portion[portion as keyof typeof Portion],
    portions: transformPortions(modifiers),
    name: decodeEntities(groupModifier.name)
  }));

function transformApiData(
  item: undefined | ItemFragment & { modifiers:Modifiers[] }
): PizzaOptions {
  const itemData = item?.modifiers;
  const pizzaSummary: PizzaSummaryOption = {
    // @ts-expect-error - existing code
    id: item?.id,
    isCYO: item?.isCYO ? IsCyo.TRUE : IsCyo.FALSE,
    name: item?.name,
    image: item?.imageURL,
    splittable: item?.splittable,
    outOfStock: item?.outOfStock,
    sodiumWarning: item?.sodiumWarning,
    glutenFree: item?.glutenFree,
    price: item?.price,
    priority: item?.priority,
    showTotalPrice: true,
    hideCrustStepCarat: false
  };

  const sizeOptions = getSizeOptions(itemData);
  const sizes = transformSizeOptions(sizeOptions);
  const crusts = transformCrustOptions(sizeOptions);
  const rules = transformRuleOptions(sizeOptions, itemData);
  const sauces = transformSauceOptions(itemData);
  const cheeses = transformCheeseOptions(itemData);
  const finishers = transformPizzaOptions(itemData, isFinisher, true);
  const meatToppings = transformPizzaOptions(itemData, isMeat);
  const veggieToppings = transformPizzaOptions(itemData, isVeggie);

  return {
    summary: pizzaSummary,
    sizes,
    crusts,
    sauces,
    cheeses,
    finishers,
    meatToppings,
    veggieToppings,
    rules
  };
}

export const recipeName = (
  apiResponse: undefined | PizzaBuilder
): string | undefined => apiResponse?.menu?.item?.name;

export default transformApiData;