import gql from 'graphql-tag';
import LinkFragmentV3 from '../fragments/link.v3';
import ImageFragmentV3 from '../fragments/image.v3';
import { HeroQuery, HeroQueryResultV3 } from '../../types/Hero';

const rawQuery = gql`
    query getHomepageHero($envEnvironmentID: String!, $storeID: String!, $occasions: [OccasionV3!], $time: String! ) {
        Homepage(envID: $envEnvironmentID, storeID: $storeID, occasions: $occasions, time: $time) {
            storeID
            heroes {
                image {
                    ...ImageFragmentV3
                }
                link {
                    ...LinkFragmentV3
                }
            }
        }
    }
    ${ImageFragmentV3}
    ${LinkFragmentV3}
`;

const parser = (data: HeroQueryResultV3) : { hero: HomepageHero } => ({
  hero: data?.Homepage?.heroes && data?.Homepage?.heroes[0]
});

// @ts-expect-error: Expect type error for parser function
const query: HeroQuery = { query: rawQuery, parser };
export default query;
