/* eslint-disable no-param-reassign */
import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import { RootState } from '@/rootStateTypes';
import {
  Deal, DealData, DealRuleOption, DealStep
} from './dealTypes';
import {
  selectCustomizationsIncluded,
  selectDeal,
  selectDealId,
  selectDealStepNameById,
  selectDealSteps,
  selectDealSummary,
  selectDealType
} from './selectors/dealsSelectors';
import {
  selectCrustIdForDeal,
  selectCrustIdandUpchargeForDeal,
  selectSizeIdForDeal
} from './selectors/ruleSelectors';

const domain = (state: RootState): DealData => state.domain.deal.data;
const dealDomain = (state: RootState): Deal => state.domain.deal;

export const dealSelectors = {
  selectDeal: compose(selectDeal, dealDomain),
  selectDealType: compose(selectDealType, domain),
  selectDealSummary: compose(selectDealSummary, domain),
  selectDealSteps: compose(selectDealSteps, domain),
  selectDealStepNameById(state: RootState, stepId: DealStep['id']): DealStep['name'] | null {
    return selectDealStepNameById(domain(state), stepId);
  },
  selectDealId: compose(selectDealId, dealDomain),

  selectCustomizationsIncluded: compose(selectCustomizationsIncluded, dealDomain),
  // rules
  selectCrustIdForDeal: compose(selectCrustIdForDeal, dealDomain),
  selectCrustIdandUpchargeForDeal: compose(selectCrustIdandUpchargeForDeal, dealDomain),
  selectSizeIdForDeal: compose(selectSizeIdForDeal, dealDomain)
};

export const initialDeal: DealData = {
  id: '',
  name: '',
  image: '',
  type: null,
  kind: AddableCartItemKinds.DEAL,
  outOfStock: false,
  steps: [] as DealStep[],
  rules: {} as DealRuleOption,
  combinable: true
};

const dealSlice = createSlice({
  name: 'deal',
  initialState: initialDeal,
  reducers: {
    updateDeal: (deal: DealData, action: PayloadAction<DealData>) => {
      Object.assign(deal, action.payload);
    },
    clearDeal: () => initialDeal
  }
});

export const {
  updateDeal,
  clearDeal
} = dealSlice.actions;

export default dealSlice.reducer;
