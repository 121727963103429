import MobileDetect from 'mobile-detect';
import React, { createContext, useContext } from 'react';

type ResponsiveContextType = {
  ua: string;
  isMobile: boolean;
  initialWidth: string;
}

interface UAProps {
  userAgent: string;
  initialWidth?: string | null;
}
const ResponsiveContext = createContext<ResponsiveContextType | null>(null);

const ResponsiveProvider = ({
  value,
  children
}: {
  value: UAProps;
  children: JSX.Element;
}): JSX.Element => {
  const { userAgent, initialWidth = null } = value;
  const md = new MobileDetect(userAgent);
  const isMobile = !!md.mobile();
  const responsiveWidth = isMobile ? 'xs' : 'lg';

  return (
    <ResponsiveContext.Provider
      value={{
        ua: userAgent,
        isMobile,
        initialWidth: initialWidth || responsiveWidth
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

const useResponsiveContext = (): ResponsiveContextType | null => useContext(ResponsiveContext);

export { ResponsiveProvider, useResponsiveContext };
