import React, { ReactElement } from 'react';
import { Drawer } from '@material-ui/core';
import ReactDOM from 'react-dom';
import RailHeader from './RailHeader';
import { railStyles } from './Rail.styles';
import useUnload from '@/hooks/useUnload';
import { RailProps } from '@/rail/Rail.types';
import telemetry from '@/telemetry';

const Rail = ({
  title, visible, onClose, children, railIcon
}: RailProps): ReactElement => {
  const classes = railStyles();

  useUnload(onClose);

  if (typeof document === 'undefined') {
    return <></>;
  }

  const portalDestination = document.querySelector('#rail');

  if (!portalDestination) {
    telemetry.addNoticeError(new Error('Rail placeholder #rail does not exist'));
    return <></>;
  }

  if (!visible) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="right"
      onClose={onClose}
      open={visible}
      data-testid="rail"
    >
      <RailHeader title={title} onClose={onClose} icon={railIcon} />
      {children}
    </Drawer>,
    portalDestination
  );
};

export default Rail;
