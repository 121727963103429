import cookie from 'next-cookies';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { UserAuthentication } from '@/common/pageSetup';
import { authState as authStateMethod } from '@/auth/userStates';
import { YUM_TOKEN_COOKIE_KEY } from '@/configuration/constants';

function getUATokenExpirationDate(token?: string): number | undefined {
  try {
    // @ts-expect-error - existing code
    const jsonPayload = jwtDecode<JwtPayload>(token);

    return new Date(jsonPayload.exp * 1000).getTime();
  } catch {
    return undefined;
  }
}

function getUserAuthenticationFromToken(uAToken?: string): UserAuthentication {
  const expirationDate = getUATokenExpirationDate(uAToken);
  const authState = authStateMethod(uAToken, expirationDate);

  return expirationDate
    ? { expirationDate, authState }
    : { authState }
}

function getUserAuthentication(ctx: any): UserAuthentication {
  const yumToken = cookie(ctx)[YUM_TOKEN_COOKIE_KEY];

  return getUserAuthenticationFromToken(yumToken);
}

export {
  getUserAuthentication,
  getUserAuthenticationFromToken
};
