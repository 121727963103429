import { Category, CategoryDisplayNameResult } from '../../types/Category';

const transformer = (data: CategoryDisplayNameResult): Partial<Category> => ({
  // @ts-expect-error - existing code
  displayName: data?.Homepage?.category.displayName,
  // @ts-expect-error - existing code
  heading: data?.Homepage?.category.heading || '',
  // @ts-expect-error - existing code
  subHeading: data?.Homepage?.category.subHeading || ''
});

export default transformer;
