import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useRouter } from 'next/router';
import { openModal, openLocalizationRail } from '../../localization/actions';
import {
  confirmLocation,
  confirmLocationStoreCloseNoFutureOrders
} from '../confirmLocation/confirmLocation';
import { shouldShowConfirmLocationModal } from '../url';
import useSessionStorage from '../useSessionStorage';
import { localizationSelectors } from '../../localization/localizationSelectors';

type ConfirmLocationHandler = (string: string, event: SyntheticEvent<HTMLAnchorElement, Event> & Event, EventListener?: EventListener) => void;
type SuppressConfirmationModal = () => void;

export enum State {
  HAS_NOT_SEEN_MODAL = 'false',
  HAS_SEEN_MODAL = 'true'
}
export const sessionStorageKey = 'confirm-loc';

const useLocationConfirmationModal = (): {
  suppressConfirmationModal: SuppressConfirmationModal;
  showLocationConfirmationModal: ConfirmLocationHandler;
} => {
  const dispatch = useDispatch();
  const occasion: OccasionChoice = useSelector(localizationSelectors.occasion);
  const storeDetails: StoreDetail = useSelector(
    localizationSelectors.storeDetails
  );
  const isLocalized = useSelector(localizationSelectors.isLocalized);
  const isStoreOpen = useSelector(localizationSelectors.isStoreOpen);
  const acceptFutureOrders = useSelector(
    localizationSelectors.acceptFutureOrders
  );
  const router = useRouter();
  // @ts-expect-error - existing code
  const [confirmLocSeen, setConfirmLocSeen]: [
    string | null,
    Dispatch<SetStateAction<string | null>>
  ] = useSessionStorage(sessionStorageKey, State.HAS_NOT_SEEN_MODAL);

  // Prevent modal from popping when localizing during an active browser session
  const suppressConfirmationModal = () => {
    setConfirmLocSeen(State.HAS_SEEN_MODAL);
  };

  const altCtaCallback = (): void => {
    dispatch(openLocalizationRail());
  };

  const showOrderFromAnotherLocationModal = () => {
    const ctaCallback = (): void => {
      dispatch(openLocalizationRail());
    };
    dispatch(openModal(confirmLocationStoreCloseNoFutureOrders(ctaCallback)));
  };

  const showLocationConfirmationModal: ConfirmLocationHandler = (
    href,
    event,
    ctaCallback = () => {}
  ): void => {
    if (
      !isLocalized
      || !shouldShowConfirmLocationModal()
      || confirmLocSeen === 'true'
    ) {
      ctaCallback(event);
      return;
    }

    event.preventDefault();
    const confirmCallback = () => {
      ctaCallback(event);
      router.push(href);
    };

    if (isStoreOpen || acceptFutureOrders) {
      dispatch(
        openModal(
          confirmLocation(
            occasion,
            storeDetails,
            altCtaCallback,
            confirmCallback
          )
        )
      );
      setConfirmLocSeen(State.HAS_SEEN_MODAL);
      return;
    }

    if (!isStoreOpen && !acceptFutureOrders) {
      showOrderFromAnotherLocationModal();
    }
  };

  return { suppressConfirmationModal, showLocationConfirmationModal };
};

export default useLocationConfirmationModal;
