import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@/common/colors';

const styles = makeStyles(() => createStyles({
  container: {
    background: colors.gray100,
    padding: '12px',
    borderBottom: `1px solid ${colors.gray400}`,
    alignItems: 'center'
  },
  alertsContainer: {
    alignItems: 'baseline'
  },
  icon: {
    paddingRight: '8px',
    display: 'flex'
  },
  span: {
    fontSize: '12px',
    width: '100%'
  },
  priceChanged: {
    position: 'relative',
    top: '-3px'
  },
  textContainer: {
    flexGrow: 1
  },
  closeIcon: {
    minWidth: '40px'
  },
  multiAlertsCloseIcon: {
    position: 'relative',
    top: '-4px'
  },
  textGrid: {
    marginBottom: '5px'
  }
}));

export default styles;
