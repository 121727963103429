import nextCookie from 'next-cookies';
import { GetServerSidePropsContext } from 'next';
import { CookieDetails } from './constants';
import logger from '@/common/logger';

const getCookie = (targetCookie: CookieDetails, serverSideCtx?: GetServerSidePropsContext, logErrors = true) => {
  try {
    let cookieVal: string | undefined;
    // Server-side
    if (serverSideCtx) {
      cookieVal = nextCookie(serverSideCtx)[targetCookie.key];
      if (cookieVal === undefined) {
        throw new Error(`No existing cookie found with key on server-side, ${targetCookie.key}`);
      }
    // Client-side
    } else {
      if (typeof document === 'undefined') throw new Error(`No document object found, when getting cookie ${targetCookie.key}`);

      const currentCookies = document.cookie.split(';');
      const cookieToParse = currentCookies.find((cookie) => cookie.trim().startsWith(targetCookie.key));

      if (!cookieToParse) throw new Error(`No existing cookie found with key, ${targetCookie.key}`);

      [, cookieVal] = cookieToParse.split('=');
    }
    if (!cookieVal) {
      throw new Error(`Cookie found with no value for key, ${targetCookie.key}`);
    }
    return { success: true, value: cookieVal };
  } catch (err) {
    if (logErrors) {
      console.error(err);
      logger.error(err as Error);
    }
    return { success: false, value: undefined };
  }
};

const setCookie = (targetCookie: CookieDetails, value: string, logErrors = true) => {
  try {
    if (!value) throw new Error(`Attempted to set cookie, ${targetCookie.key} with no value`);
    if (typeof document === 'undefined') throw new Error(`No document object found, when setting cookie ${targetCookie.key}`);

    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (targetCookie.expiresInMins * (60 * 1000)));
    document.cookie = `${targetCookie.key}=${value}; expires=${expirationDate.toUTCString()}; path=/`;

    return { success: true };
  } catch (err) {
    if (logErrors) {
      console.error(err);
      logger.error(err as Error);
    }
    return { success: false };
  }
};

const deleteCookie = (targetCookie: CookieDetails, logErrors = true) => {
  try {
    if (typeof document === 'undefined') throw new Error(`No document object found, when deleting cookie ${targetCookie.key}`);

    document.cookie = `${targetCookie.key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;

    return { success: true };
  } catch (err) {
    if (logErrors) {
      console.error(err);
      logger.error(err as Error);
    }
    return { success: false };
  }
};

export { getCookie, setCookie, deleteCookie };
