import {
  CartItemInput, CartModifierInput, ItemType, Placement, Portion
} from '@pizza-hut-us-development/client-core';
import {
  BuiltPizza,
  PizzaIngredient,
  PlacementChoice,
  PortionChoice
} from '@/builders/pizza/dataTransformers/builderTypes';

const transformPlacement = (placement: PlacementChoice | Placement): Placement => {
  switch (placement) {
    case 'left':
      return Placement.LEFT;
    case 'right':
      return Placement.RIGHT;
    case 'LEFT':
      return Placement.LEFT;
    case 'RIGHT':
      return Placement.RIGHT;
    default:
      return Placement.WHOLE;
  }
};

const transformPortion = (portion: PortionChoice): Portion => {
  switch (portion) {
    case 'none':
      return Portion.NONE;
    case 'light':
      return Portion.LIGHT;
    case 'extra':
      return Portion.EXTRA;
    default:
      return Portion.REGULAR;
  }
};

const getWeightCode = (ingredient: PizzaIngredient): string | undefined => {
  const { weightCode, placement, portion } = ingredient;

  if (weightCode) {
    return ingredient.weightCode;
  }

  if (placement && portion) {
    return `${placement}_${portion}`.toUpperCase();
  }

  return undefined;
};

const transformIngredient = (ingredient: PizzaIngredient): CartModifierInput => {
  const placement = ingredient.placement ? transformPlacement(ingredient.placement) : undefined;
  const portion = ingredient.portion ? transformPortion(ingredient.portion) : undefined;
  return {
    id: ingredient.id,
    name: ingredient.name,
    type: ingredient.type as ItemType,
    placement,
    portion,
    slotCode: ingredient.slotCode,
    weightCode: getWeightCode(ingredient),
    modifiers: []
  };
};

const transformIngredients = (ingredients: PizzaIngredient[]): CartModifierInput[] => ingredients.map(transformIngredient);

export const legacyPizzaTransformer = (item: BuiltPizza, isPizzaEditNoSauceFixEnabled = false): CartItemInput => {
  let variantCode: string | undefined;

  if (item.crust !== null) {
    variantCode = item?.crust?.variantCode || item.variantCode;
  }

  const meatToppingsModifiers = item.meatToppings ? transformIngredients(item.meatToppings) : [];
  const veggieToppingsModifiers = item.veggieToppings ? transformIngredients(item.veggieToppings) : [];
  const itemFormattedSauce = item.sauce?.id === 'no-sauce/portion' ? undefined : item.sauce;
  const potentialModifiers = variantCode
    ? [item.cheese, item.finisher, isPizzaEditNoSauceFixEnabled ? itemFormattedSauce : item.sauce]
    : [item.crust, item.cheese, item.finisher, isPizzaEditNoSauceFixEnabled ? itemFormattedSauce : item.sauce, item.size];

  const additionalModifiers = potentialModifiers.flatMap((ingredient) => (ingredient !== null && ingredient !== undefined ? transformIngredient(ingredient) : []));

  const modifiers: CartModifierInput[] = [...meatToppingsModifiers, ...veggieToppingsModifiers, ...additionalModifiers];

  const transformedItem: CartItemInput = {
    id: item.id ?? '',
    name: item.name,
    type: item.type as ItemType,
    quantity: item.quantity,
    specialInstructions: item.specialInstructions,
    modifiers,
    variantCode
  };

  return transformedItem;
};
