import React, { ComponentProps, ReactComponentElement } from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import colors from '../../../common/colors';

const useStyles = makeStyles(() => ({
  root: {
    color: colors.gray900,
    fontFamily: 'open_sans_semi',
    fontSize: '10px',
    lineHeight: 2,
    letterSpacing: '0.6px',
    border: '1px solid #cfcecc',
    borderRadius: '3px',
    background: 'transparent',
    position: 'relative',
    bottom: '3px'
  },
  label: {
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  sizeSmall: {
    height: '20px'
  }
}));

export default function StoreClosedBadge(props: ComponentProps<typeof Chip>): ReactComponentElement<'div'> {
  const classes = useStyles();

  return (
    <Chip
      {...props}
      size="small"
      label="STORE CLOSED"
      classes={{ ...classes }}
    />
  );
}
