import { Dispatch } from 'redux';

import authService, { YumRefreshTokenData } from '@/services/authService';
import telemetry from '@/telemetry';
import { getSelectors, OfSelectorsAndSuch } from '@/common/logout/actions';
import {
  authTokenExpired,
  refreshAuthTokenAfterSessionExpiry
} from '@/auth/actions';
import { AuthState } from '@/auth/userStates';
import logger from '@/common/logger';
import { RootState } from '@/rootStateTypes';
import { UserAuthentication } from '@/common/pageSetup';
import Time from '@/common/time';
import { StoreState } from '@/store';
import { setIsAuthenticated } from '@/../optimizely/utils/attributeHelpers';
import { getOrInitializeOptimizely } from '@/../optimizely/optimizely';
import { setAuthState } from '@/header/actions';

export const AUTH_TOKEN_UPDATED_EVENT = 'ph/authTokenUpdated';

// We want to refresh preemptively before the token expires - this is
// how much earlier than expiration:
export const millisecondsToRefreshBeforeExpiration = Time.minutesToMilliseconds(2);
export const millisecondsBeforeConsideredInactive = Time.minutesToMilliseconds(45);
export const maxGuestTokenLength = 500;

let yumRefreshTimeoutId: NodeJS.Timeout | undefined;

export type InitializeAuthTokenHelpersProps = {
  getState: () => RootState;
  dispatch: Dispatch<StoreState>;
  localized: boolean;
  initLocalizationToken?: string;
  userAuthentication?: UserAuthentication;
  shouldCreateCart?: boolean;
  shouldGetCart?: boolean;
  selectors?: OfSelectorsAndSuch;
  yumAuthRefreshData?: YumRefreshTokenData;
  onAfterInit?: () => void;
};

export type InitializeAuthTokenHelpersPropsWithSelectors = {
  selectors: OfSelectorsAndSuch;
} & InitializeAuthTokenHelpersProps;

export type AuthTokenUpdatedEventDetail = {
  previousUserId?: string;
  currentUserId?: string;
};

const msToRefreshBeforeExpire = 1_000 * 60;

const refreshLogger = (tokenExpAt: number, timeoutMs: number) => {
  const now = Date.now();
  logger.withoutTelemetry.debug('====================================');
  logger.withoutTelemetry.debug(`Token expires in: ${Math.floor((tokenExpAt - now) / msToRefreshBeforeExpire)} minutes`);
  logger.withoutTelemetry.debug(`Token will refresh in: ${Math.floor(timeoutMs / msToRefreshBeforeExpire)} minutes`);
  logger.withoutTelemetry.debug(`Token will refresh at: ${new Date((new Date()).getTime() + timeoutMs).toString()}`);
  logger.withoutTelemetry.debug('====================================');
};

export const fetchExpiryTime = async (expiresAt?: number, tokenName?: string): Promise<number> => {
  if (expiresAt) return expiresAt;
  const { result } = await authService.getYumAccessTokenExpiration(tokenName);
  return result || 0;
};

export const refreshTokenAndReinitialize = async (props: InitializeAuthTokenHelpersPropsWithSelectors): Promise<number | null | undefined> => {
  const { result: newTokenExpAt } = await authService.refreshYumAccessToken();
  if (newTokenExpAt) {
    if (props?.userAuthentication?.authState !== AuthState.GUEST) {
      props.dispatch(refreshAuthTokenAfterSessionExpiry());
    }
    telemetry.addCustomEvent('yum-access-token-refreshed');
  }
  return newTokenExpAt;
};

export const handleTokenRefreshFailure = async (props: InitializeAuthTokenHelpersPropsWithSelectors): Promise<void> => {
  props.dispatch(authTokenExpired());
  props.dispatch(setAuthState(AuthState.GUEST));
  telemetry.addCustomAttribute('authState', AuthState.GUEST);
  await initializeAuthTokenHelpers({ ...props, shouldGetCart: false, userAuthentication: { authState: AuthState.GUEST } });
};

export const getAccessTokenUserID = async () => {
  try {
    const { result } = await authService.getYumAccessTokenUserId();
    if (result === null) {
      return '';
    }
    return result;
  } catch (error) {
    return '';
  }
};

export const dispatchAuthTokenUpdatedEvent = async (previousUserId?: string) => {
  if (!previousUserId) {
    window.dispatchEvent(new CustomEvent<AuthTokenUpdatedEventDetail>(AUTH_TOKEN_UPDATED_EVENT, { detail: {} }));
    return;
  }

  const currentUserId = await getAccessTokenUserID();
  window.dispatchEvent(new CustomEvent<AuthTokenUpdatedEventDetail>(AUTH_TOKEN_UPDATED_EVENT, { detail: { currentUserId, previousUserId } }));
};

export const handleYumTokenRefresh = async (expiresAt: number | undefined, props: InitializeAuthTokenHelpersPropsWithSelectors): Promise<void> => {
  logger.withoutTelemetry.debug('handleYumTokenRefresh');
  telemetry.addCustomEvent('yum-access-token-refresh-timeout-set');

  const isGuest = props?.userAuthentication?.authState === AuthState.GUEST;
  const tokenExpAt = await fetchExpiryTime(expiresAt);
  const timeoutMs = tokenExpAt ? tokenExpAt - Date.now() - msToRefreshBeforeExpire : 0;

  refreshLogger(tokenExpAt, timeoutMs);

  const fetchTokenAndReinitialize = async () => {
    const newTokenExpAt = await refreshTokenAndReinitialize(props);

    if (!newTokenExpAt) {
      if (isGuest) {
        logger.withoutTelemetry.debug('Token Refresh failure. NOT retrying...');
        logger.error(new Error('Unable to refresh Yum guest token. NOT retrying.'));
      } else {
        logger.error(new Error('Unable to refresh Yum token. Falling back to guest.'));
        await handleTokenRefreshFailure(props);
      }
      return;
    }

    dispatchAuthTokenUpdatedEvent();
    handleYumTokenRefresh(newTokenExpAt, props);
  };

  if (timeoutMs <= 0) {
    await fetchTokenAndReinitialize();
    return;
  }

  if (yumRefreshTimeoutId) clearTimeout(yumRefreshTimeoutId);

  yumRefreshTimeoutId = setTimeout(async () => {
    await fetchTokenAndReinitialize();
  }, timeoutMs);
};

export async function initializeAuthTokenHelpers(props: InitializeAuthTokenHelpersProps) : Promise<void> {
  const {
    getState,
    userAuthentication
  } = props;
  const defaultProps = {
    shouldCreateCart: false,
    shouldGetCart: false,
    selectors: getSelectors(getState)
  };
  const propsWithDefaults: InitializeAuthTokenHelpersPropsWithSelectors = { ...defaultProps, ...props };

  const optimizely = getOrInitializeOptimizely();

  if (yumRefreshTimeoutId) clearTimeout(yumRefreshTimeoutId);

  try {
    const response = await optimizely?.onReady({ timeout: 2_000 });
    if (!response?.success) {
      throw new Error('Optimizely not ready after 2000ms timeout');
    }
  } catch (err) {
    const error = err instanceof Error ? err : new Error(typeof err === 'string' ? err : 'Unknown error');
    telemetry.addNoticeError(error);
  }

  let isAuthenticated = false;
  const { authState } = userAuthentication || {};

  logger.withoutTelemetry.debug(`User state is: ${authState}`);
  switch (authState) {
    case AuthState.LOGGED_IN:
      await handleYumTokenRefresh(userAuthentication?.expirationDate, propsWithDefaults);
      isAuthenticated = true;
      break;
    case AuthState.GUEST:
      logger.withoutTelemetry.debug('Creating Yum guest token');
      await authService.createYumGuestToken();
      dispatchAuthTokenUpdatedEvent();
      logger.withoutTelemetry.debug('Handling Yum guest token refresh');
      await handleYumTokenRefresh(userAuthentication?.expirationDate, propsWithDefaults);
      break;
    case AuthState.EXPIRED:
      logger.withoutTelemetry.debug('Attempting to refresh Yum token');
      await handleYumTokenRefresh(0, propsWithDefaults);
      isAuthenticated = true;
      break;
    default:
      break;
  }

  props.onAfterInit?.();

  setIsAuthenticated(isAuthenticated);
}
