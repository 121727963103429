import { InstrumentationAdapter } from './types';

class NewRelicBrowser implements InstrumentationAdapter {
  agent = (window as any).newrelic;

  addCustomAttribute(name: string, value: string) {
    this.agent.setCustomAttribute(name, value);
  }

  addCustomEvent(name: any, attributes: any) {
    this.agent.addPageAction(name, attributes);
  }

  addNoticeError(error: Error, customAttributes?: {}) {
    this.agent.noticeError(error, customAttributes);
  }
}

export default NewRelicBrowser;
