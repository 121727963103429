import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  computeComponentKey,
  getJoinedText
} from '../../common/string-formatter';
import { getLinkContent } from '../../graphql/gqlDataManipulation';
import FooterSectionHeader from './FooterSectionHeader';
import AnchorLink from '../../common/AnchorLink';
import getLinkLangAttribute from '../../common/getLinkLangAttribute';
import telemetry from '../../telemetry';
import { RootState } from '@/rootStateTypes';
import dataAnalytics from '@/dataAnalytics';
import { onFooterLinkClick } from '@/dataAnalytics/dataAnalyticsHelper';

interface ExpandedViewProps {
  links: [Links];
  classes: { [key: string]: string };
  sectionName?: string;
}
const ExpandedView = ({
  sectionName = '',
  links,
  classes
}: ExpandedViewProps): JSX.Element => {
  const analyticsDataModel = useSelector((state: RootState) => state.analyticsDataModel);

  const onLinkClick = (displayText: string) => {
    telemetry.addCustomEvent('footer-navigation-link-click');
    const screenName = window.location.pathname === '/' ? '/home' : window.location.pathname;
    dataAnalytics.push(
      onFooterLinkClick(screenName, displayText),
      analyticsDataModel
    );
  };
  return (
    <div role="navigation">
      <FooterSectionHeader
        sectionName={sectionName}
        className={classes.expandedSectionHeader}
      />
      <ul className={classes.navigationList}>
        {links.map((link: Links, index: number) => {
          const {
            link: linkUrl,
            linkDisplayText = '',
            linkTitle,
            linkType,
            external,
            onClick,
            tabIndex,
            preventHref
          } = getLinkContent(link) || {};

          return (
            <li
              className={classes.footerLinkParagraph}
              key={computeComponentKey(linkDisplayText, index)}
            >
              <Grid
                onClick={() => onLinkClick(linkDisplayText)}
              >
                <AnchorLink
                  link={linkUrl}
                  preventHref={preventHref}
                  linkTitle={linkTitle}
                  linkType={linkType}
                  linkDisplayText={linkDisplayText}
                  external={external}
                  onClick={onClick}
                  className={classes.subLink}
                  testId={`footer-${getJoinedText(linkDisplayText)}-link`}
                  dataAnalyticsCategory="global_footer"
                  dataAnalyticsAction={linkDisplayText}
                  tabIndex={tabIndex || 0}
                  lang={getLinkLangAttribute(linkDisplayText)}
                  // @ts-expect-error - existing code
                  ariaHaspopup={linkTitle.toLowerCase() === 'sign in'}
                />
              </Grid>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ExpandedView;
