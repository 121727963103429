import {
  useState, useEffect, Dispatch, SetStateAction
} from 'react';
import isClientSide from '../isClientSide/isClientSide';

const useSessionStorage = (
  key: string,
  initialValue?: string
): [string, Dispatch<SetStateAction<string>>] => {
  const onClientSide = isClientSide();
  const [state, setState] = useState(onClientSide ? sessionStorage.getItem(key) : initialValue);

  useEffect(() => {
    // @ts-expect-error - existing code
    sessionStorage.setItem(key, state);
  }, [key, state]);

  // @ts-expect-error - existing code
  return [state, setState];
};

export default useSessionStorage;
