import {
  trace, Span, Tracer
} from '@opentelemetry/api';
import { InstrumentationAdapter } from './types';

const formatName = (name: string): string => name.toLowerCase().split(' ').join('_');

class SplunkTelemetryAdapter implements InstrumentationAdapter {
  agent: Tracer;

  tracerName?: string;

  spanName?: string;

  globalAttributes: Record<string, string | number>;

  constructor() {
    this.agent = trace.getTracer('phc_web_trace');
    this.globalAttributes = {};
  }

  addCustomAttribute(name: string, value: string): void {
    this.globalAttributes[name] = value;
  }

  addCustomEvent(name: string, attributes?: Record<string, string | number>): void {
    this.setTracerName(name);
    this.getOrUseTracer();
    this.setSpanName(name);

    const span = this.startSpan();
    if (span) {
      span.setAttributes({
        'workflow.name': formatName(name),
        status: 'success',
        ...(attributes ? { ...attributes } : {}),
        ...this.globalAttributes
      });

      span.end();
    }
  }

  addNoticeError(error: Error, attributes?: Record<string, string | number>): void {
    this.setTracerName('error-tracer');
    this.getOrUseTracer();
    this.setSpanName('error-instance');

    const span = this.startSpan();
    if (span) {
      span.setStatus({ code: 2, message: error.message });
      span.recordException(error);
      span.setAttributes({
        error: true,
        'error.message': error.message,
        'error.stack': error.stack || 'No stack trace available',
        'error.type': error.name,
        ...(attributes ? { ...attributes } : {}),
        ...this.globalAttributes
      });

      span.end();
    }
  }

  private setTracerName(name: string): void {
    this.tracerName = `${formatName(name)}_trace`;
  }

  private setSpanName(name: string): void {
    this.spanName = `${formatName(name)}_span`;
  }

  private startSpan(): Span {
    return this.agent.startSpan(this.spanName ?? '', { startTime: Date.now() });
  }

  private getOrUseTracer(): void {
    this.agent = trace.getTracer(this.tracerName ?? '');
  }
}

export default SplunkTelemetryAdapter;
