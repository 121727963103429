const removeDecimalDFromPrice = (dollarPrice: string): string => dollarPrice.split('.')[0];

const formattedPrice = (
  price?: number | null,
  removeDecimal = false,
  style: 'decimal' | 'currency' | 'percent' | 'unit' = 'currency'
): string => {
  const dollarPrice = new Intl.NumberFormat('en-US',
    { style, currency: 'USD' })
    .format((price ?? 0) / 100);

  return removeDecimal ? removeDecimalDFromPrice(dollarPrice) : dollarPrice;
};

export default formattedPrice;
