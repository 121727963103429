import {
  BaseAnalytics,
  BaseProductsAnalytics,
  BeaconIds,
  DealBaseAnalytics,
  ItemAnalytics,
  LineupProductsAnalytics,
  MenuProductAnalytics,
  PageViewAnalytics,
  PageViewWithItemsAnalytics
} from '../analyticsTypes';
import {
  commonMenuBaseAnalytics,
  commonPizzaBuilderClickAnalytics,
  getItemsWithoutSizeAndModifiers
} from '@/dataAnalytics/dataAnalyticsHelper';
import { LINEUP } from '@/deals/constants';
import { parsePrice, ProductTileCommonEventsParam } from '@/menu/categories/categories.analytics';

export const onLoadDealLoversPage = (
  items: ItemAnalytics[]
): PageViewWithItemsAnalytics => ({
  event: 'screen_view',
  screen_name: 'Builder > Deal Builder > Deal Lovers',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Deal Lovers',
  screen_level4: 'Builder > Deal Builder > Deal Lovers',
  level2_breadcrumb: 'Deal Builder > Deal Lovers',
  level3_aggregation: 'Deal Lovers',
  beacon_id: BeaconIds.LINEUP.LINEUP_PAGE_LOAD,
  deal_id: 'Deal Lovers',
  deal_name: 'Deal Lovers',
  items: [...getItemsWithoutSizeAndModifiers(items)]
});

export const onLineupToastCheckoutClick = (): PageViewAnalytics => ({
  beacon_id: BeaconIds.LINEUP.TOAST.CHECKOUT_CLICK,
  event: 'button_click',
  event_action: 'Added to Order Toast > Checkout',
  event_label: 'Builder > Deal Builder > Lineup > Added to Order Toast > Checkout',
  screen_name: 'Builder > Deal Builder > Lineup > Added to Order Toast',
  event_category: 'Builder > Deal Builder > Lineup',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Lineup',
  screen_level4: 'Builder > Deal Builder > Lineup > Added to Order Toast',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: 'Lineup > Added to Order Toast',
  deal_id: LINEUP,
  deal_name: LINEUP
});

export const onDealLoversAddToCartSuccess = (
  item: ItemAnalytics, quantity: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonMenuBaseAnalytics('Deal Lovers', true, 'Deal Lovers', 'Deal Lovers'),
  beacon_id: BeaconIds.LINEUP.ADDED_TO_CART,
  event: 'cart_add',
  event_action: 'Add to Order',
  event_label: 'Builder > Deal Builder > Deal Lovers > Add to Order',
  screen_name: 'Builder > Deal Builder > Deal Lovers',
  event_category: 'Builder > Deal Builder > Deal Lovers',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Deal Lovers',
  screen_level4: 'Builder > Deal Builder > Deal Lovers',
  level2_breadcrumb: 'Deal Builder > Deal Lovers',
  level3_aggregation: 'Deal Lovers',
  deal_id: 'Deal Lovers',
  deal_name: 'Deal Lovers',
  product_action: 'Add',
  product_name_hit: item.item_name,
  products: [{
    product_index: 0,
    product_id: item.item_id ?? '',
    product_name: item.item_name,
    product_category: 'Deal Lovers',
    product_quantity: quantity,
    product_price: item.item_price,
    product_position: item.item_position,
    product_action_list: 'Deal Lovers',
    product_added_location: 'Deal Lovers',
    product_size: item.item_size ?? '',
    product_modifier: item.item_modifier ?? '',
    product_deal_id: 'Deal Lovers',
    product_deal_name: 'Deal Lovers'
  }]
});

export const onDealLoversPagePizzaCTAClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.LINEUP.BUILD_PIZZA_CTA_LINK,
  screen_name: 'Builder > Deal Builder > Deal Lovers',
  event: 'button_click',
  event_category: 'Builder > Deal Builder > Deal Lovers',
  event_action: 'Build Pizza',
  event_label: 'Builder > Deal Builder > Deal Lovers > Build Pizza',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Deal Lovers',
  screen_level4: 'Builder > Deal Builder > Deal Lovers',
  level2_breadcrumb: 'Deal Builder > Deal Lovers',
  level3_aggregation: 'Deal Lovers',
  deal_id: 'Deal Lovers',
  deal_name: 'Deal Lovers'
});

export const onLineupSelectSizeOrAdditionalOption = (
  {
    category,
    item,
    price,
    quantity,
    size,
    modifier
  }: ProductTileCommonEventsParam,
  isInfoRail = false
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  beacon_id: isInfoRail ? BeaconIds.MENU.INFO_RAIL_MODIFIER_CLICK : BeaconIds.MENU.MODIFIER_CLICK,
  event: 'button_click',
  screen_name: 'Builder > Deal Builder > Lineup',
  event_category: 'Builder > Deal Builder > Lineup',
  event_action: `${isInfoRail ? 'Product Info Slide > ' : ''}${modifier}`,
  event_label: `Builder > Deal Builder > Lineup > ${isInfoRail ? 'Product Info Slide > ' : ''}`
    + `${modifier}`,
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Lineup',
  screen_level4: 'Builder > Deal Builder > Lineup',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: LINEUP,
  deal_id: LINEUP,
  deal_name: LINEUP,
  product_name_hit: item.item_name,
  products: [{
    product_index: item.item_index,
    product_action_list: 'Lineup',
    product_id: item.item_id ?? '',
    product_name: item.item_name,
    product_category: category,
    product_quantity: quantity,
    product_price: price,
    product_position: item.item_position,
    product_size: size,
    product_modifier: modifier,
    product_deal_id: LINEUP,
    product_deal_name: LINEUP
  }]
});

export const onLineupBackArrowClick = (
  pizzaName: string, section: string
): DealBaseAnalytics => ({
  beacon_id: BeaconIds.LINEUP.PIZZA_BUILDER.BACK_BUTTON_CLICK,
  event: 'button_click',
  deal_id: LINEUP,
  deal_name: LINEUP,
  screen_name: 'Builder > Deal Builder > Pizza Builder > Lineup',
  event_category: 'Builder > Deal Builder',
  event_action: `${section} > Back`,
  event_label: `Builder > Deal Builder > ${section} > Back`,
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Pizza Builder',
  screen_level4: 'Builder > Deal Builder > Pizza Builder > Lineup',
  level2_breadcrumb: 'Deals > Pizza Builder',
  level3_aggregation: 'Pizza Builder > Lineup',
  product_name_hit: pizzaName
});

export const onLineupContinueClick = (
  pizzaName: string
): DealBaseAnalytics => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const { form_name, ...common } = commonPizzaBuilderClickAnalytics(pizzaName, undefined, undefined, true);
  return {
    ...common,
    beacon_id: BeaconIds.LINEUP.PIZZA_BUILDER.CONTINUE_BUTTON_CLICK,
    event_action: 'Sauce, Cheese > Continue',
    event_category: 'Builder > Deal Builder',
    event_label: 'Builder > Deal Builder > Sauce, Cheese > Continue'
  };
};

export const onLineupLocalizedProductTileAddToCartSuccessFromInfoRail = (
  item: ItemAnalytics,
  quantity: number
): LineupProductsAnalytics<MenuProductAnalytics> => ({
  beacon_id: BeaconIds.MENU.ADD_TO_CART.INFO_RAIL_SUCCESS,
  event: 'cart_add',
  screen_name: 'Builder > Deal Builder > Lineup',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Lineup',
  screen_level4: 'Builder > Deal Builder > Lineup',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: LINEUP,
  deal_id: LINEUP,
  deal_name: LINEUP,
  product_name_hit: item.item_name,
  product_action: 'Add',
  products: [{
    product_index: item.item_index,
    product_action_list: LINEUP,
    product_id: item.item_id ?? '',
    product_name: item.item_name,
    product_category: LINEUP,
    product_quantity: quantity,
    product_price: parsePrice(item.item_price),
    product_position: item.item_position,
    product_size: item.item_size ?? null,
    product_modifier: item.item_modifier ?? null,
    product_deal_id: LINEUP,
    product_deal_name: LINEUP,
    product_added_location: 'Lineup > Product Info Slide'
  }]
});

export const onLineupToastView = (productName: string): Partial<BaseAnalytics> => ({
  beacon_id: BeaconIds.MENU.TOAST.INITIAL_LOAD,
  event: 'screen_view',
  screen_name: 'Builder > Deal Builder > Lineup > Added to Order Toast',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Lineup',
  screen_level4: 'Builder > Deal Builder > Lineup > Added to Order Toast',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: 'Lineup > Added to Order Toast',
  product_name_hit: productName,
  deal_id: LINEUP,
  deal_name: LINEUP
});
