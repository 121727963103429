import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InfoRailStyles from '../../InfoRail/InfoRail.styles';
import useStyles from './styles';
import { ProductInfoContentProps } from '@/menu/categories/menu';
import ProductOptionsDropdown from '@/common/components/LocalizedProductTile/ProductOptionsDropdown';
import SelectorButton from '@/coreComponents/buttons/SelectorButton';
import RenderNutritionInfo from '../RenderNutritionInfo';
import { UPSELL } from '@/deals/constants';
import { MELT } from '@/domain/constants';

const ProductInfoContent = (props: ProductInfoContentProps): JSX.Element => {
  const classes = useStyles();
  const {
    quantity,
    showQuantity = true,
    handleAddToCart,
    handleAnalyticsForInfoRailSlideOut,
    productNameWithoutSpaces,
    updateQuantity,
    categoryName,
    formattedUnitPrice,
    hasMultipleSizes,
    selectedSize,
    updateSize,
    hasAdditionalOptions,
    selectedAdditionalOption,
    updateAdditionalOption,
    additionalOptions,
    productId,
    hasNutrition,
    buttonState,
    quantitySelectorInputLabel,
    buttonDetails,
    product,
    showDescription = true,
    isMyHutBoxProduct
  } = props;

  const isPizzaMelt = categoryName?.toLowerCase() === MELT.toLowerCase();

  const {
    id, name, description, sizes
  } = product;

  useEffect(() => {
    if (categoryName !== UPSELL) {
      handleAnalyticsForInfoRailSlideOut(categoryName, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, categoryName]);

  const classesInfoRails = InfoRailStyles();
  return (
    <div className={classes.root}>
      <Typography className={classesInfoRails.productName}>
        {name}
      </Typography>
      {formattedUnitPrice && (
        <Typography
          className={classesInfoRails.price}
          data-testid="product-info-rail-price"
        >
          {formattedUnitPrice}
        </Typography>
      )}
      {showDescription && (
        <Typography className={classesInfoRails.description}>
          {description}
        </Typography>
      )}
      <Grid className={classes.sizeDropdownAndCtaWrapper}>
        {hasMultipleSizes && (
          <ProductOptionsDropdown
            id={id}
            productName={name}
            productNameWithoutSpaces={productNameWithoutSpaces}
            formattedUnitPrice={formattedUnitPrice}
            selectedProductOption={selectedSize}
            productOptions={sizes}
            updateProductOption={updateSize}
            label="Sizes"
            type="sizes"
          />
        )}
        {hasAdditionalOptions && !isPizzaMelt && (
          <ProductOptionsDropdown
            id={id}
            productName={name}
            productNameWithoutSpaces={productNameWithoutSpaces}
            formattedUnitPrice={formattedUnitPrice}
            selectedProductOption={selectedAdditionalOption}
            productOptions={additionalOptions}
            updateProductOption={updateAdditionalOption}
            label="Additional Options"
            type="additional-options"
          />
        )}
        <Grid className={classes.sizeDropdownAndCtaButtonWrapper}>
          {!isMyHutBoxProduct
            && (
            <SelectorButton
              onClick={(e) => handleAddToCart(e as MouseEvent, false, selectedSize?.upcharge ?? 0)}
              onSelectionChange={updateQuantity}
              quantity={quantity}
              showQuantity={showQuantity}
              inputLabelText={quantitySelectorInputLabel}
              button={buttonDetails}
              select={{
                id: `product-info-rail-${productNameWithoutSpaces}`,
                name: 'quantity'
              }}
              state={buttonState}
            />
            )}
        </Grid>
      </Grid>
      {hasNutrition && (
        <RenderNutritionInfo
          productId={productId}
          selectedSize={selectedSize}
          isMenuTile={false}
          selectedAdditionalOption={selectedAdditionalOption}
        />
      )}
    </div>
  );
};

export default ProductInfoContent;
