export default function getGeoLocation() : Promise<any> {
  return new Promise((resolve, reject) => {
    const successCallback = (position: { coords: GeolocationCoordinates}) => {
      const latlong = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      resolve(latlong);
    };

    const errorCallback = (err: GeolocationPositionError | string) => {
      reject(err);
    };

    if (!navigator.geolocation) {
      errorCallback('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
  });
}
