import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { DiningOccasion } from '@pizza-hut-us-development/client-core';
import {
  Grid, makeStyles, createStyles, Theme
} from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';

import {
  selectDeliveryStore,
  switchToCarryout,
  switchToDelivery,
  searchDeliveryByLatLng,
  searchDeliveryByLatLngV2
} from '../actions';
import StoreTile from './StoreTile';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import { Occasion } from '../constants';
import LinkButton from '../../common/LinkButton';
import DeliveryAddresses from './DeliveryAddresses';
import { storeResultsAnalytics, storeResultsViewButtonAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';
import colors from '../../common/colors';

import { RootState } from '@/rootStateTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { transformDeliveryPromiseTime } from '../common/transformPromiseTime';
import CaliforniaDeliveryFeeAdvisory from '@/common/CaliforniaDeliveryFeeAdvisory';
import useLocalizationRail from '@/clientCore/localization/localizationRail/useLocalizationRail';
import useLocalization from '@/clientCore/localization/useLocalization';
import { transformCCStoreToStoreDetail } from '@/clientCore/localization/helpers';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  storesNear: {
    fontFamily: 'open_sans_semi',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.33,
    margin: '24px 0 16px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px',
      marginTop: '16px'
    }
  },
  storeGroup: {
    marginTop: '24px'
  },
  line: {
    color: colors.gray400,
    marginLeft: '8px',
    marginRight: '8px'
  },
  storeTile: {
    paddingBottom: '16px'
  },
  deliveryFeeWarning: {
    backgroundColor: colors.gray100,
    borderRadius: '8px',
    marginBottom: '16px',
    padding: '16px'
  },
  deliveryFeeWarningText: {
    marginLeft: '8px'
  }
}));

interface DeliveryResultsProps {
  address: DeliveryAddress;
  stores: Array<StoreDetail>;
  selectDeliveryStore: (options: {
    storeNumber: string;
    deliveryAddress: DeliveryAddress;
    removeAlcohol: boolean;
    storeToken: string;
    onSuccess?: (localizationToken: string) => void;
  }) => void;
  searchDeliveryByLatLngV2: (options: DeliveryAddress, skipGeocode?: boolean) => void;
  switchToDelivery: () => void;
  switchToCarryout: () => void;
  onEdit?: () => void;
  suggestedDeliveryAddresses: Array<DeliveryAddress>;
}

function DeliveryResults({
  address,
  onEdit,
  suggestedDeliveryAddresses,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  searchDeliveryByLatLngV2, switchToCarryout, switchToDelivery
}: DeliveryResultsProps) {
  const classes = useStyles();
  const analytics = useAnalytics();

  const [{ enabled: californiaDeliveryWarningEnabled }] = useDecision('ops_dtg436_display_cali_delivery_warning');
  const [{ enabled: populateCartWithCoordinatesEnabled }] = useDecision('fr-web-4083-populate-cart-with-coordinates');

  const { results, deliveryAddress: ccDeliveryAddress } = useLocalizationRail();
  const { localizeDelivery } = useLocalization();
  const transformedStores = useMemo(() => results?.map((ccStore) => transformCCStoreToStoreDetail(ccStore, DiningOccasion.DELIVERY)) ?? [], [results]);

  const searchAddress: DeliveryAddress = useMemo(() => ({
    address: ccDeliveryAddress?.address1,
    address2: ccDeliveryAddress?.address2,
    city: ccDeliveryAddress?.city,
    state: ccDeliveryAddress?.state,
    zipcode: ccDeliveryAddress?.postalCode
  } as DeliveryAddress), [ccDeliveryAddress]);

  const deliveryStore = transformedStores?.[0];
  const deliveryPromiseTime = transformDeliveryPromiseTime(deliveryStore?.promiseTime, deliveryStore?.promiseTimeStale);

  const storeResults = transformedStores;

  useEffect(() => {
    analytics.push(() => storeResultsAnalytics('Delivery', { zipcode: address.zipcode }, storeResults?.length ?? 0, deliveryPromiseTime, deliveryStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinue = (storeNumber: string) => {
    const storeResponse = results?.find((ccStore) => ccStore.storeId === storeNumber);
    if (storeResponse && ccDeliveryAddress) {
      if (populateCartWithCoordinatesEnabled && storeResponse?.deliveryLatitude && storeResponse?.deliveryLongitude) {
        localizeDelivery(storeResponse, {
          ...ccDeliveryAddress,
          position: {
            coordinates: [
              parseFloat(storeResponse.deliveryLongitude),
              parseFloat(storeResponse.deliveryLatitude)
            ]
          }
        });
      } else {
        localizeDelivery(storeResponse, ccDeliveryAddress);
      }
    }
  };

  const onEditClick = () => {
    if (onEdit) onEdit();
    switchToDelivery();
    analytics.push(() => storeResultsViewButtonAnalytics('Delivery', 'Edit location'));
  };

  const onSwitchClick = () => {
    if (onEdit) onEdit();
    switchToCarryout();
    analytics.push(() => storeResultsViewButtonAnalytics('Delivery', 'Change to carryout'));
  };

  if (suggestedDeliveryAddresses) {
    return (
      <DeliveryAddresses
        suggestedAddresses={suggestedDeliveryAddresses}
        userAddress={address}
        onEditClick={onEditClick}
        onSwitchClick={onSwitchClick}
        searchDelivery={searchDeliveryByLatLngV2}
      />
    );
  }

  const shouldDisplayCaliforniaDeliveryWarning = storeResults?.[0]?.state === 'CA' && storeResults?.[0].baseDeliveryCharge && californiaDeliveryWarningEnabled;
  const californiaDeliveryWarning = shouldDisplayCaliforniaDeliveryWarning ? (<CaliforniaDeliveryFeeAdvisory fee={storeResults?.[0]?.baseDeliveryCharge} />) : null;

  return searchAddress && (
    <Grid wrap="nowrap" className={classes.content} data-testid="deliveryResults" container direction="column" spacing={0}>
      <Grid item data-testid="delivery-info" className={classes.storesNear}>
        {searchAddress.address} {searchAddress.address2}
        <br />
        {searchAddress.city}
        {searchAddress.state && <span>, {searchAddress.state}</span>} {searchAddress.zipcode}
      </Grid>
      <Grid item>
        <LinkButton testId="edit-location" onClick={onEditClick}>
          Edit location
        </LinkButton>
        <span className={classes.line}>|</span>
        <LinkButton testId="change-occasion" onClick={onSwitchClick}>
          Change to carryout
        </LinkButton>
      </Grid>
      <Grid wrap="nowrap" item container className={classes.storeGroup} direction="column" alignItems="stretch">
        {californiaDeliveryWarning}
        {storeResults.map((storeDetail, index) => (
          <Grid item key={index} className={classes.storeTile}>
            <StoreTile
              index={index}
              occasion={Occasion.DELIVERY}
              store={storeDetail}
              deliveryAddress={address}
              onContinue={onContinue}
              onSwitchOccasion={onSwitchClick}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: RootState) => ({
  address: state.presentational.localization.previousSearchDetails,
  stores: state.domain.localization.stores,
  suggestedDeliveryAddresses: state.presentational.localization.suggestedDeliveryAddresses
});

const mapDispatchToProps = {
  selectDeliveryStore,
  switchToCarryout,
  switchToDelivery,
  searchDeliveryByLatLng,
  searchDeliveryByLatLngV2
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryResults);
